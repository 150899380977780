import AspApi from "../../../api/ApiConfig";

export const Login = async (payLoad) =>
  await AspApi.post("api/v1/svc/aaa/authentication/login", payLoad);

export const otpValidate = async (payLoad) =>
  await AspApi.post("api/v1/otp/validate", payLoad);

export const forgotPassword = async (payLoad) =>
  await AspApi.post("api/v1/svc/aaa/authentication/forgot_password", payLoad);

export const resetPassword = async (payload) =>
  await AspApi.post("api/v1/svc/aaa/authentication/change_password", payload);

export const passwordLinkValidate = async (params) =>
  await AspApi.get(
    `api/v1/svc/aaa/authentication/password_link_validate?token=${params}`
  );

//sign-up
export const SignUpApi = async (payLoad) => {
  const res = await AspApi.post(
    "/api/v1/svc/aaa/authentication/user_signup",
    payLoad
  );
  return res;
};

export const ResendApi = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/send_email_verification_code",
    payLoad
  );
  return res;
};

export const EmailOTPValidation = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/email_verification_code_validate",
    payLoad
  );
  return res;
};

export const ChangePassword = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/set_password",
    payLoad
  );
  return res;
};

export const emailApi = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/email_verification_validate",
    payLoad
  );
  return res;
};
