import AspApi from "api/ApiConfig";
import { Base64 } from "js-base64";

// CRM - SINGLE CONTACT ADD  -------------------

export const countryListApi = async () => {
  const res = await AspApi.get(`/api/v1/svc/aaa/authentication/get_countries`);
  return res;
};

export const cityListApi = async (provinceId) => {
  const res = await AspApi.get(
    `/api/v1/svc/aaa/authentication/get_cities?province_id=${provinceId}`
  );
  return res;
};
export const stateListApi = async (countryId) => {
  const res = await AspApi.get(
    `/api/v1/svc/aaa/authentication/get_provinces?country_id=${countryId}`
  );
  return res;
};

export const contactOriginApi = async () => {
  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_utils/contact_origin_types_list`
  );
  return res;
};

export const checkingApi = async (brandId, query) => {
  let qpm = [];

  if (query?.contact_type)
    qpm.push(`contact_type=${encodeURIComponent(query.contact_type)}`);
  if (query?.phone_number)
    qpm.push(`phone_number=${encodeURIComponent(query.phone_number)}`);
  if (query?.phone_country_code)
    qpm.push(
      `phone_country_code=${encodeURIComponent(query.phone_country_code)}`
    );
  if (query?.email) qpm.push(`email=${encodeURIComponent(query.email)}`);

  const qpmString = qpm.length > 0 ? `&${qpm.join("&")}` : "";
  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_utils/check_contact_exists?brand_id=${brandId}${qpmString}`
  );
  return res;
};

export const saveSingleContactApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/contacts/contact_persons?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const addTagsApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/contacts/tag?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const tagsListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  // if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/contacts/tag?brand_id=${brandId}&${qpmString}`
  );

  return res;
};

// CRM - TABLE  -------------------

export const crmListShow = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.filters)
    qpm.push(`filters=${Base64.encode(JSON.stringify(query?.filters))}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_persons?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

// CRM - SingleContactView  -------------------

export const crmSingleContactView = async (brandId, id) => {
  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_persons/${id}?brand_id=${brandId}`
  );
  return res;
};

export const crmContactTypes = async () => {
  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_utils/contact_categories_list`
  );
  return res;
};

export const crmProfessions = async () => {
  const res = await AspApi.get(
    `/api/v1/svc/contacts/contact_utils/professions_list`
  );
  return res;
};

// CRM - EDIT

export const crmSingleContactEdit = async (payLoad, id) => {
  const res = await AspApi.patch(
    `/api/v1/svc/contacts/contact_persons/${id}`,
    payLoad
  );
  return res;
};

//CRM-CLEAR
export const crmClear = async (contactPersonId, locationId, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/svc/contacts/contact_persons/${contactPersonId}/location/${locationId}?brand_id=${brandId}`
  );
  return res;
};

export const crmTagsEdit = async (id, brandId, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/svc/contacts/tag/${id}?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const crmTagSingleAssign = async (contactId, id, brandId) => {
  const res = await AspApi.patch(
    `/api/v1/svc/contacts/contact_persons/${contactId}/tag/${id}/assign_tag?brand_id=${brandId}`
  );
  return res;
};

export const crmTagDelete = async (id, brandId) => {
  const res = await AspApi.delete(
    `/api/v1//svc/contacts/tag/${id}?brand_id=${brandId}`
  );
  return res;
};

export const crmTagRemove = async (contactId, id, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/svc/contacts/contact_persons/${contactId}/tag/${id}/unassign_tag?brand_id=${brandId}`
  );
  return res;
};

//CRM ADD
export const crmSingleContactAdd = async (payLoad, id) => {
  const res = await AspApi.post(
    `/api/v1/svc/contacts/contact_persons/${id}/contact_details`,
    payLoad
  );
  return res;
};

export const crmPhoneDelete = async (id, contactId, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/svc/contacts/contact_persons/${id}/contact_details/${contactId}?brand_id=${brandId}`
    // { data: payLoad }
  );
  return res;
};
