import React, { useCallback, useEffect, useRef, useState } from "react";
import "../styles/campaignsView.css";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// constants
import { VIEW_TYPES } from "../constants/SingleCampaign";
import { cn } from "../../../helper/cn";
import { ICONS } from "../../../assets/icons";
import moment from "moment";
import FilterHome from "../../../components/commonComponents/filterHome/FilterHome";
import { HomeFilterList } from "../../../constant/app/Filter/Filter";
import {
  getCampaignOverviewCount,
  getCampaignPerformanceCount,
  getGoalInfoCount,
} from "../api";
import { useAspSelector } from "../../../test/jest-redux-hooks";
import html2canvas from "html2canvas";
import { goalTypeConvertion } from "../constants/campaigns";

import Download from "../../../assets/customSVG/campaigns/Download";
import { Tooltip } from "antd";

const campaignStatus = {
  11: "Running",
  12: "Completed",
  10: "Scheduled",
};

const getStyle = (id) => {
  switch (id) {
    case 1:
    case "Engagement":
      return {
        backgroundColor: "#F472B6",
        color: "white",
        icon: ICONS?.engagementWhite,
      }; // Engagement
    case 3:
    case "Leads":
      return {
        backgroundColor: "#04B8A2",
        color: "white",
        icon: ICONS?.leadsWhite,
      }; // Leads
    case 2:
    case "Awareness":
      return {
        backgroundColor: "#3B82F6",
        color: "white",
        icon: ICONS?.awarenessWhite,
      };
    default:
  }
};

const getCampaignStyle = (id) => {
  switch (id) {
    case "Running":
      return {
        backgroundColor: "#25C277",
        color: "white",
        icon: ICONS?.CampaignRunningIconWhite,
      };
    case "Completed":
      return {
        backgroundColor: "#4C90F5",
        color: "white",
        icon: ICONS?.campaignCompleteIconNew,
      };
    case "Scheduled":
      return {
        backgroundColor: "#F6A723",
        color: "white",
        icon: ICONS?.campaignScheduledNew,
      };
    default:
  }
};

export default function SingleCampaign() {
  const { id } = useParams();
  const campaignId = id;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const location = useLocation();
  const campaignOverviewData = location?.state?.data;

  const [OverviewList, setOverviewList] = useState(null);
  const [performanceList, setPerformanceList] = useState(null);
  const [goalInfo, setGoalInfo] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [filter, setFilter] = useState([]);
  const captureRef = useRef(null);
  const [loaders, setLoader] = useState(false);

  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const { currentBrand } = useAspSelector((state) => state?.app);
  const brandId = currentBrand?.brand_id;

  // const campaignStartDate = moment(
  //   campaignOverviewData?.starting_date_and_time
  // );
  // const campaignEndDate = moment(campaignOverviewData?.ending_date_and_time);
  // const today = moment();

  // const initialEndDate =
  //   campaignEndDate.isValid() && campaignEndDate.isBefore(today)
  //     ? campaignEndDate
  //     : campaignStartDate.isValid() && campaignStartDate.isAfter(today)
  //     ? campaignStartDate
  //     : today;

  // const initialStartDate =
  //   campaignStartDate.isValid() && campaignStartDate.isSame(today)
  //     ? today
  //     : campaignStartDate.isValid() &&
  //       moment(initialEndDate).subtract(6, "days").isBefore(campaignStartDate)
  //     ? campaignStartDate
  //     : moment(initialEndDate).subtract(6, "days");

  // const [startDate, setStartDate] = useState(
  //   initialStartDate.format("YYYY-MM-DD")
  // );
  // const [endDate, setEndDate] = useState(initialEndDate.format("YYYY-MM-DD"));

  // const handleFilterChange = useCallback(
  //   (filter) => {
  //     const end =
  //       campaignEndDate.isValid() && campaignEndDate.isBefore(today)
  //         ? campaignEndDate
  //         : campaignStartDate.isValid() && campaignStartDate.isAfter(today)
  //         ? campaignStartDate
  //         : today;

  //     let start = moment(end).subtract(
  //       filter === "Last 7 days"
  //         ? 6
  //         : filter === "Last 14 days"
  //         ? 13
  //         : filter === "Last 28 days"
  //         ? 27
  //         : 30,
  //       "days"
  //     );

  //     if (campaignStartDate.isValid() && campaignStartDate.isSame(today)) {
  //       start = today;
  //     }

  //     if (campaignStartDate.isValid() && start.isBefore(campaignStartDate)) {
  //       start = campaignStartDate;
  //     }

  //     setStartDate(start.format("YYYY-MM-DD"));
  //     setEndDate(end.format("YYYY-MM-DD"));
  //   },
  //   [campaignOverviewData]
  // );

  const today = moment();
  const campaignEndDate = moment(customEndDate);
  const referenceEndDate = campaignEndDate?.isBefore(today)
    ? campaignEndDate
    : today;

  const initialEndDate = referenceEndDate.format("YYYY-MM-DD");
  const initialStartDate = referenceEndDate
    .clone()
    .subtract(6, "days")
    .format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const takeScreenshot = async () => {
    try {
      const element = captureRef.current;

      element.style.position = "relative";
      element.style.zIndex = "9999";
      element.style.padding = "0px";
      element.style.backgroundColor = "#f9f9f9";

      const canvas = await html2canvas(element, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
        backgroundColor: "#f9f9f9",
      });

      // Convert canvas to image and trigger download
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "screenshot.png";
      link.click();
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
  };

  const typeId = campaignOverviewData?.campaign_type_id;
  const { icon } = getStyle(typeId);

  const BroadcastOverviewList = () => {
    setLoader(true);
    if (campaignId && brandId) {
      getCampaignOverviewCount(campaignId, brandId, startDate, endDate).then(
        (res) => {
          if (res?.data) {
            setLoader(false);
            setOverviewList(res?.data);
          }
        }
      );
    }
  };

  const CampaignPerformanceList = () => {
    setLoader(true);

    const payLoad = {
      startDate,
      endDate,
      ...(filter && { filter_action: filter }),
    };

    if (campaignId && brandId) {
      getCampaignPerformanceCount(campaignId, brandId, payLoad).then((res) => {
        if (res?.data) {
          setLoader(false);
          setPerformanceList(res?.data);
        }
      });
    }
  };

  const CampaignGoalInfo = () => {
    setLoader(true);
    if (campaignId && brandId) {
      getGoalInfoCount(campaignId, brandId, startDate, endDate).then((res) => {
        if (res?.data) {
          setLoader(false);
          setGoalInfo(res?.data);
        }
      });
    }
  };

  useEffect(() => {
    const isOverviewPage =
      location?.pathname === `/user/campaign/view/${campaignId}/overview`;

    if (isOverviewPage && startDate !== null && endDate !== null) {
      CampaignPerformanceList(
        currentBrand?.brand_id,
        campaignId,
        startDate,
        endDate
      );

      BroadcastOverviewList(
        currentBrand?.brand_id,
        campaignId,
        startDate,
        endDate
      );

      CampaignGoalInfo(currentBrand?.brand_id, campaignId, startDate, endDate);
    }
  }, [currentBrand, campaignId, startDate, endDate, location]);

  const handleFilterChange = useCallback(
    (filter) => {
      // const campaignEndDate = moment(customEndDate);
      // const today = moment();

      // const referenceEndDate = campaignEndDate.isBefore(today)
      //   ? campaignEndDate
      //   : today;

      let newStartDate, newEndDate;

      newEndDate = referenceEndDate.format("YYYY-MM-DD");

      switch (filter) {
        case "Last 7 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(6, "days")
            .format("YYYY-MM-DD");
          break;
        case "Last 14 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(13, "days")
            .format("YYYY-MM-DD");
          break;
        case "Last 28 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(27, "days")
            .format("YYYY-MM-DD");
          break;
        default:
          newStartDate = referenceEndDate
            .clone()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
      }

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [filter, customEndDate]
  );

  // const [startDate, setStartDate] = useState(
  //   moment().subtract(6, "days").format("YYYY-MM-DD")
  // );
  // const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  // const handleFilterChange = useCallback(
  //   (filter) => {
  //     let newStartDate, newEndDate;

  //     const campaignStartDate = `${moment(
  //       campaignOverviewData?.starting_date_and_time
  //     ).format("MMM D, YYYY")}`;
  //     const today = moment();

  //     switch (filter) {
  //       case "Last 7 days":
  //         newStartDate = moment().subtract(6, "days").format("YYYY-MM-DD");
  //         newEndDate = moment().format("YYYY-MM-DD");
  //         break;
  //       case "Last 14 days":
  //         newStartDate = moment().subtract(13, "days").format("YYYY-MM-DD");
  //         newEndDate = moment().format("YYYY-MM-DD");
  //         break;

  //       case "Last 28 days":
  //         newStartDate = moment().subtract(27, "days").format("YYYY-MM-DD");
  //         newEndDate = moment().format("YYYY-MM-DD");
  //         break;

  //       default:
  //         newStartDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  //         newEndDate = moment().format("YYYY-MM-DD");
  //     }

  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   },
  //   [filter]
  // );

  const handlePerformance = (value) => {
    setFilter((prevFilter) => {
      if (prevFilter.includes(value)) {
        return [];
      } else {
        return [value];
      }
    });
  };

  useEffect(() => {
    if (filter !== null && startDate !== null && endDate !== null) {
      CampaignPerformanceList();
    }
  }, [filter]);

  useEffect(() => {
    getViewType();
  }, [pathname]);

  const getViewType = useCallback(() => {
    const splittedPath = pathname?.split("/");
    const value = splittedPath[splittedPath?.length - 1];
    const res = VIEW_TYPES?.map((t) => {
      if (t?.key === value) return { ...t, isSelected: true };
      return t;
    });
    setViewType(res);
  }, [pathname]);

  const handleBackClick = useCallback(() => {
    navigate("/user/campaign/all-campaigns");
  }, [navigate]);

  // const duration = `${moment(
  //   campaignOverviewData?.starting_date_and_time
  // ).format("MMM D, YYYY")} - ${moment(
  //   campaignOverviewData?.ending_date_and_time
  // ).format("MMM D, YYYY")}`;

  const extractDate = (isoString) => {
    const date = new Date(isoString.split("T")[0]);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  useEffect(() => {
    if (
      campaignOverviewData?.starting_date_and_time &&
      campaignOverviewData?.ending_date_and_time
    ) {
      const startDates = extractDate(
        campaignOverviewData.starting_date_and_time
      );
      const endDates = extractDate(campaignOverviewData.ending_date_and_time);

      setCustomStartDate(startDates);
      setCustomEndDate(endDates);
    }
  }, [campaignOverviewData]);

  const duration = `${customStartDate} - ${customEndDate}`;

  const date = new Date();
  const goalInfoDate1 = moment(campaignOverviewData?.ending_date_and_time).diff(
    moment(date),
    "days"
  );

  // const goalInfoDate =
  //   moment(campaignOverviewData?.ending_date_and_time).date() -
  //   moment(date).date();

  const endingDate = moment(campaignOverviewData?.ending_date_and_time)
    .utc()
    .startOf("day");
  const currentDate = moment(date).local().startOf("day");

  const goalInfoDate = endingDate.diff(currentDate, "days");

  return (
    <div className="w-full px-5 py-9 flex flex-col gap-6">
      <div className="flex-row align-center space-between pl-10">
        <div className="flex-row align-center gap-2">
          <span
            className="font14px-400 text-[var(--font-400)] pointer hover:text-[#6940F2] hover:underline"
            onClick={handleBackClick}
          >
            Campaigns
          </span>
          <img
            src={ICONS?.chevronRightIcon}
            alt="arrowLeftCampaign"
            className="pointer"
          />
          <p className="campaign-view">
            {pathname === `/user/campaign/view/${campaignId}/execution`
              ? "Campaign Execution list"
              : "Campaign Overview"}
          </p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-4  pr-4">
            {viewType?.map((t) => {
              return (
                <div
                  key={t?.id}
                  className={cn(
                    "px-3.5 py-1.5 rounded-md header-titles-contents",
                    t?.isSelected
                      ? "bg-[var(--new-primary-bg)] !text-[var(--new-primary)] border border-[var(--new-primary-bg)]"
                      : " hover:bg-[var(--BG-50)] border text-[var(--contentText)]",
                    t?.disabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  )}
                  onClick={() => {
                    navigate(`/user/campaign/view/${id}/${t?.key}`, {
                      state: { data: campaignOverviewData },
                    });
                  }}
                  data-testId="overviewClick"
                >
                  {t?.label}
                </div>
              );
            })}
          </div>
          <div className="border-l border-[--border-input]">
            {location?.pathname ===
              `/user/campaign/view/${campaignId}/overview` && (
              <Tooltip title="Download Reports">
                <div className="group ml-2 flex-row align-center pointer hover:bg-[var(--new-primary-bg)] b-radius-6 pl-1 border border-[var(--mainLayoutBG)] py-1.5 text-[1rem] font-[500] text-[var(--font-600)] hover:text-[var(--new-primary)] hover:border-[var(--new-primary-bg)]">
                  {/* <img src={ICONS?.reportDownload} alt="reportDownload" /> */}
                  <Download
                    className="stroke-[var(--font-600)] group-hover:stroke-[var(--new-primary)]"
                    color=""
                  />
                  <div className={cn("px-2")} onClick={takeScreenshot}>
                    Reports
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-7">
        <div className="w-full h-24 flex items-center justify-between gap-1 px-3 py-2.5 bg-white rounded-2xl">
          <div className="flex flex-col gap-1.5">
            <div className="flex items-center">
              <div className=" flex items-center justify-center">
                <div
                  className={`rounded-[4px] ${
                    campaignOverviewData?.display_color ? "ml-10 size-7" : ""
                  }`}
                  style={{
                    background:
                      campaignOverviewData?.display_color ??
                      campaignOverviewData?.display_color,
                  }}
                />
                <span className="main-header ml-10 mr-10">
                  {campaignOverviewData?.display_emoji ??
                    campaignOverviewData?.display_emoji}
                </span>
              </div>
              <p className="campaign-name-titles">
                {campaignOverviewData?.name &&
                  campaignOverviewData?.name?.trim()?.charAt(0).toUpperCase() +
                    campaignOverviewData?.name?.trim()?.slice(1).toLowerCase()}
              </p>

              <div
                className="w-fit px-2.5 py-1 text-sm !font-[500] rounded-md bg-[var(--primary)] text-white flex-row align-center ml-10"
                style={getCampaignStyle(
                  campaignStatus[campaignOverviewData?.status]
                )}
              >
                {getCampaignStyle(campaignStatus[campaignOverviewData?.status])
                  ?.icon && (
                  <img
                    src={
                      getCampaignStyle(
                        campaignStatus[campaignOverviewData?.status]
                      )?.icon
                    }
                    alt=""
                    className="mr-1 inline-block"
                  />
                )}

                <div>{campaignStatus[campaignOverviewData?.status]}</div>
              </div>
            </div>
            <div className="flex items-center gap-2.5 pl-2.5 ">
              <div
                className="b-radius-6 flex-row align-center mr-5 px-2 py-1 new-body-layout"
                style={getStyle(
                  goalTypeConvertion[
                    campaignOverviewData?.campaign_goal_type_id
                  ]
                )}
              >
                {icon && <img src={icon} alt="" className="w-5 mr-1" />}
                {
                  goalTypeConvertion[
                    campaignOverviewData?.campaign_goal_type_id
                  ]
                }
              </div>
              <div className="px-4 border-l-2  border-r-2 border-[var(--border-100)]">
                <div
                  className="flex items-center gap-2
                new-body-text"
                >
                  <img src={ICONS?.whatsApp} alt="" />
                  <span className="!font-[600]">WhatsApp</span>
                </div>
              </div>
              <div className="flex items-center gap-2.5 ml-2">
                <span className="new-sidebar">Duration</span>
                <span className="new-header2">{duration}</span>
              </div>
            </div>
          </div>
          <div className="flex-row justify-between items-center pt-20 prl-10 ml-10 mr-10">
            {location?.pathname === `/user/campaign/view/${id}/overview` && (
              <FilterHome
                filterList={HomeFilterList}
                fetchApiByFilter={handleFilterChange}
                brandId={currentBrand?.brand_id}
                campaignId={campaignId}
                dataTestId="campaignPerformance_filter_test"
                dataTestIdClick="campaignPerformance_filter_click"
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                customStartDate={customStartDate}
                customEndDate={customEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </div>
        </div>
        <Outlet
          context={{
            performanceList,
            OverviewList,
            goalInfo,
            goalInfoDate,
            campaignOverviewData,
            handlePerformance,
            filter,
            takeScreenshot,
            loaders,
            startDate,
            endDate,
            captureRef,
          }}
        />
      </div>
    </div>
  );
}
