import AspApi from "../../../api/ApiConfig";

export const SignUpApi = async (payLoad) => {
  const res = await AspApi.post(
    "/api/v1/svc/aaa/authentication/user_signup",
    payLoad
  );
  return res;
};

export const ResendApi = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/send_email_verification_code",
    payLoad
  );
  return res;
};

export const EmailOTPValidation = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/email_verification_code_validate",
    payLoad
  );
  return res;
};

export const ChangePassword = async (payLoad) => {
  const res = await AspApi.post(
    "api/v1/svc/aaa/authentication/set_password",
    payLoad
  );
  return res;
};
