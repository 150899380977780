import React from "react";

const yourTemplates = ({ color, fill, stroke }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.0835 10.0007C2.0835 6.2687 2.0835 4.40273 3.24286 3.24335C4.40224 2.08398 6.26821 2.08398 10.0002 2.08398C13.7321 2.08398 15.5981 2.08398 16.7575 3.24335C17.9168 4.40273 17.9168 6.2687 17.9168 10.0007C17.9168 13.7326 17.9168 15.5986 16.7575 16.758C15.5981 17.9173 13.7321 17.9173 10.0002 17.9173C6.26821 17.9173 4.40224 17.9173 3.24286 16.758C2.0835 15.5986 2.0835 13.7326 2.0835 10.0007Z"
          stroke={color}
          stroke-width="1.67"
          fill={fill}
        />
        <path
          d="M9.16675 5.83398H14.1667"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
        <path
          d="M5.8335 5.83398H6.66683"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
        <path
          d="M5.83325 10H6.66659"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
        <path
          d="M5.8335 14.166H6.66683"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
        <path
          d="M9.16675 10H14.1667"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
        <path
          d="M9.16675 14.166H14.1667"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default yourTemplates;
