import React from "react";

const Home = ({ color }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_16221_70719)">
          <path
            d="M12.5 14.167C11.8338 14.6857 10.9585 15.0003 10 15.0003C9.04143 15.0003 8.16628 14.6857 7.5 14.167"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
          />
          <path
            d="M1.95884 11.0116C1.66466 9.09716 1.51757 8.14002 1.87949 7.29148C2.24141 6.44293 3.04437 5.86235 4.6503 4.70121L5.85017 3.83366C7.84792 2.38922 8.84678 1.66699 9.99937 1.66699C11.1519 1.66699 12.1508 2.38922 14.1485 3.83366L15.3484 4.70121C16.9544 5.86235 17.7573 6.44293 18.1192 7.29148C18.4811 8.14002 18.334 9.09716 18.0399 11.0116L17.789 12.644C17.3719 15.3577 17.1634 16.7147 16.1902 17.5242C15.2169 18.3337 13.794 18.3337 10.9484 18.3337H9.05037C6.20463 18.3337 4.78177 18.3337 3.80852 17.5242C2.83526 16.7147 2.62674 15.3577 2.20971 12.644L1.95884 11.0116Z"
            stroke={color}
            stroke-width="1.67"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16221_70719">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Home;
