import React, { useState } from "react";
import "../styles/home.css";

import { ICONS } from "../../../assets/icons/index";

const Notifications = () => {
  const [notificationIndex, setNotificationIndex] = useState(1);
  const [isVisible, setIsVisible] = useState(true);

  const notifications = [
    "Your WhatsApp business account was disconnected",
    "Your WhatsApp business account is reconnected",
  ];

  const notify = [
    "You,re currently using Gainwix demo account which ends in 3 days",
    "You,re currently using Gainwix demo account which ends in 1 days",
  ];

  const handleChevronClick = () => {
    setNotificationIndex((prevIndex) => (prevIndex % notifications.length) + 1);
  };

  const handleChevronClick1 = () => {
    setNotificationIndex((prevIndex) => (prevIndex % notify.length) + 1);
  };

  const handleCloseNotifications = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;
  return (
    <div
      className="homeHeader text-[0.85rem] flex items-center justify-between"
      style={{
        background:
          "linear-gradient(90deg, #02d1c5 0%, #b49ff9 25%, #fcf7ef 50%, #b49ff9 75%, #02d1c5 100%)",
      }}
      // style={{
      //   background: `
      //     linear-gradient(0deg, rgba(255, 84, 62, 0.16), rgba(255, 84, 62, 0.16))
      //   `,
      // }}
      // style={{
      //   background: `
      //   linear-gradient(0deg, rgba(10, 205, 149, 0.16), rgba(10, 205, 149, 0.16))
      // `,
      // }}
    >
      <marquee>
        <div class="scrolling-text">
          <p>
            Announcement: New update - Sequence feature
            <button class="bg-black text-white py-1 px-2 rounded-xl text-[0.75rem] ml-2">
              View Demo
            </button>
          </p>
          <p class="ml-[10vw]">
            Announcement: New update - Sequence feature
            <button class="bg-black text-white py-1 px-2 rounded-xl text-[0.75rem] ml-2">
              View Demo
            </button>
          </p>
          <p class="ml-[10vw]">
            Announcement: New update - Sequence feature
            <button class="bg-black text-white py-1 px-2 rounded-xl text-[0.75rem] ml-2">
              View Demo
            </button>
          </p>
        </div>
      </marquee>

      {/* <div className="flex items-center justify-center flex-grow">
        <img
          src={ICONS?.chevronDownCarousel}
          className="rotate-90 pointer"
          onClick={handleChevronClick} // Add click handler to the first chevron
        />

        <p className="text-[#FF543E] font-[500] text-[1rem] pl-3">
          ({notificationIndex}/{notifications.length} Notifications)
          {notifications[notificationIndex - 1]}
        </p>
        <button className="bg-white py-1 px-2 rounded-xl text-[0.75rem] ml-2 mr-3 ">
          Connect
        </button>

        <img
          src={ICONS?.chevronDownCarousel}
          className="-rotate-90 pointer"
          onClick={handleChevronClick} // Add click handler to the second chevron
        />
      </div> */}

      {/* <div className="flex items-center justify-center flex-grow">
        <img
          src={ICONS?.chevronDownCarousel}
          className="rotate-90 pointer"
          onClick={handleChevronClick1}
        />

        <p className="font-[500] text-[1rem] pl-3">
          ({notificationIndex}/{notify.length} Notifications)
          {notify[notificationIndex - 1]}
        </p>
        <button className="bg-black text-white py-1 px-2 rounded-xl text-[0.75rem] ml-2 mr-3 ">
          Upgrade
        </button>

        <img
          src={ICONS?.chevronDownCarousel}
          className="-rotate-90 pointer"
          onClick={handleChevronClick1}
        />
      </div> */}

      <div className="">
        <img
          className="pointer mr-5 w-[2vw] h-[2vw]"
          src={ICONS.popupX}
          alt="popup"
          onClick={handleCloseNotifications}
        />
      </div>
    </div>
  );
};

export default Notifications;
