import { ICONS } from "../../../assets/icons";

export const PHONENUMBERLIST = [
  {
    id: 1,
    label: "9087567897",
    status: 10,
    isSelected: false,
  },
  {
    id: 2,
    label: "9087567897",
    status: 11,
    isSelected: false,
  },
  {
    id: 3,
    label: "9087567897",
    status: 12,
    isSelected: false,
  },
];

export const COUNTRYLIST = [
  {
    id: 1,
    label: "India",
    status: 10,
    isSelected: false,
  },
  {
    id: 2,
    label: "Japan",
    status: 11,
    isSelected: false,
  },
  {
    id: 3,
    label: "United States",
    status: 12,
    isSelected: false,
  },
  {
    id: 4,
    label: "Singapore",
    status: 12,
    isSelected: false,
  },
  {
    id: 5,
    label: "Malaysia",
    status: 12,
    isSelected: false,
  },
  {
    id: 6,
    label: "China",
    status: 12,
    isSelected: false,
  },
  {
    id: 7,
    label: "North Korea",
    status: 12,
    isSelected: false,
  },
  {
    id: 8,
    label: "Pakistan",
    status: 12,
    isSelected: false,
  },
  {
    id: 9,
    label: "Bangladesh",
    status: 12,
    isSelected: false,
  },
  {
    id: 10,
    label: "Thailand",
    status: 12,
    isSelected: false,
  },
];

export const overview = [
  {
    id: 1,
    label: "All conversations",
    value: 0,
    values: [
      {
        id: 1,
        label: "Marketing",
        color: "#32CDCD",
        value: 0,
      },
      {
        id: 2,
        label: "Utility",
        color: "#AC2F82",
        value: 0,
      },
      {
        id: 3,
        label: "Authentication",
        color: "#F96056",
        value: 0,
      },
      {
        id: 4,
        label: "Service",
        color: "#0ACD95",
        value: 84375834,
      },
    ],
  },
  {
    id: 2,
    label: "Free conversations",
    value: 0,
    values: [
      {
        id: 1,
        label: "Free tier",
        color: "#32CDCD",
        value: 0,
      },
      {
        id: 2,
        label: "Free entry point",
        color: "#AC2F82",
        value: 0,
      },
    ],
  },
  {
    id: 3,
    label: "Paid conversations",
    value: 0,
    values: [
      {
        id: 1,
        label: "Marketing",
        color: "#32CDCD",
        value: 0,
      },
      {
        id: 2,
        label: "Utility",
        color: "#AC2F82",
        value: 0,
      },
      {
        id: 3,
        label: "Authentication",
        color: "#F96056",
        value: 0,
      },
      {
        id: 4,
        label: "Service",
        color: "#0ACD95",
        value: 0,
      },
    ],
  },
  {
    id: 4,
    label: "Charges",
    value: 0,
    values: [
      {
        id: 1,
        label: "Marketing",
        color: "#32CDCD",
        value: 0,
      },
      {
        id: 2,
        label: "Utility",
        color: "#AC2F82",
        value: 0,
      },
      {
        id: 3,
        label: "Authentication",
        color: "#F96056",
        value: 0,
      },
      {
        id: 4,
        label: "Service",
        color: "#0ACD95",
        value: 0,
      },
    ],
  },
];

export const allConversationsData = [
  {
    id: 1,
    label: "Marketing",
    count: 0,
    value: 9,
    color: "#9747FF",
    key: "marketing",
  },
  {
    id: 2,
    label: "Utility",
    count: 0,
    value: 8,
    color: "#F6A723",
    key: "utility",
  },
  {
    id: 3,
    label: "Authentication",
    count: 0,
    value: 10,
    color: "#3E93FF",
    key: "authentication",
  },
  {
    id: 4,
    label: "Service",
    count: 0,
    value: -2,
    color: "#00D998",
    key: "service",
  },
];

export const freeConversationsData = [
  {
    id: 1,
    label: "Free tier",
    count: 0,
    value: 9,
    color: "#3E93FF",
    key: "free tier",
  },
  {
    id: 2,
    label: "Free entry point",
    count: 0,
    value: 8,
    color: "#00D998",
    key: "free entry point",
  },
];

export const paidConversationsData = [
  {
    id: 1,
    label: "Marketing",
    count: 0,
    value: 9,
    color: "#9747FF",
    key: "marketing",
  },
  {
    id: 2,
    label: "Utility",
    count: 0,
    value: 8,
    color: "#F6A723",
    key: "utility",
  },
  {
    id: 3,
    label: "Authentication",
    count: 0,
    value: 10,
    color: "#3E93FF",
    key: "authentication",
  },
  {
    id: 4,
    label: "Service",
    count: 0,
    value: -2,
    color: "#00D998",
    key: "service",
  },
];

export const approximateChargesData = [
  {
    id: 1,
    label: "Marketing",
    count: 0,
    value: 9,
    color: "#9747FF",
    key: "marketing",
  },
  {
    id: 2,
    label: "Utility",
    count: 0,
    value: 8,
    color: "#F6A723",
    key: "utility",
  },
  {
    id: 3,
    label: "Authentication",
    count: 0,
    value: 10,
    color: "#3E93FF",
    key: "authentication",
  },
  {
    id: 4,
    label: "Service",
    count: 0,
    value: -2,
    color: "#00D998",
    key: "service",
  },
];

export const insightsChart = [
  { id: 1, text: "Paid Conversation" },
  { id: 2, text: "Approximate Charges" },
];
