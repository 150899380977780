import { Sidebar } from '../components/sidebar';
import Main from '../components/main';
import '../styles/crm-styles.css';
import { Outlet } from 'react-router-dom';

// grid-cols-[calc(15%+7px),1fr]

const index = () => {
  return (
    <div className="grid  w-full h-full">
      {/* <Sidebar /> */}
      <Outlet />
    </div>
  );
};

export default index;
