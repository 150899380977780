import React, { useEffect, useState } from "react";

import "../../styles/whatsappHeader.css";

// assets
import { ICONS } from "../../../../assets/icons";

// utils
import { Modal, Skeleton, Tooltip } from "antd";

// hooks
import useToggle from "../../../../hooks/useToggle";

// redux
import { useDispatch } from "react-redux";
import { resetTemplateDetails } from "../../../../reduxToolkit/templateSlice";

// components
import CopyLink from "../../../../components/commonComponents/copyLink";
import Header from "../../../../components/commonComponents/modals/Header";

const ChannelHeader = ({ onClick, data, isLoading, syncing }) => {
  const [isModalVisible, setIsModalVisible] = useToggle(false);
  const dispatch = useDispatch();

  const handleModel = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-full bg-[var(--textWhite)] flex-row items-center space-between border-b-[0.08vw] border-[var(--font-50)]">
      <div className="flex-row align-center gap-[0.5vw] pb-3">
        <img
          src={ICONS?.whatsappInbox}
          alt="whatsapp"
          className="w-[3.3vw] p-[0.15vw] rounded-full border-[0.1vw] border-[rgba(232, 232, 234, 1)]"
        />
        <div className="flex-column pl-[0.5vw]">
          {/* <Skeleton
            // paragraph={{ rows: 2 }}
            style={{}}
            loading={isLoading}
            className=""
          > */}
          <span className="text-[1.06vw] font-[700] text-[var(--textBlack)] pb-[0.4vw] tracking-[0.02vw]">
            {data?.whatsapp_business_account_name}
          </span>
          <span className="text-[0.8vw] font-[400] text-[var(--font-400)] tracking-[0.02vw]">
            WhatApp Account ID: {data?.whatsapp_business_account_id}
          </span>
          {/* </Skeleton> */}
        </div>
      </div>
      <div className="flex-row align-center space-between gap-[0.8vw]">
        <Tooltip trigger="hover" title="Sync account" placement="top">
          <div
            className={`px-3 py-[0.497vw] rounded-md flex items-center border  ${
              syncing ? "cursor-not-allowed" : "pointer"
            }`}
            onClick={() => {
              if (!syncing) onClick();
            }}
          >
            <img
              src={ICONS?.syncRefreshTemplate}
              alt="syncRefresh"
              className={`w-[1vw] h-[1vw] ${
                syncing ? "animate-spin" : "animate-none"
              }`}
            />
            <span className="text-[0.975rem] text-[#616874] font-[500] pl-2">
              Sync
            </span>
          </div>
        </Tooltip>

        <Tooltip trigger="hover" title="Disconnect" placement="top">
          <div
            className={`px-3 py-[0.497vw] rounded-md flex items-center border border-[#FF543E]  ${
              syncing ? "cursor-not-allowed" : "pointer"
            }`}
            // onClick={() => {
            //   if (!syncing) onClick();
            // }}
          >
            <img
              src={ICONS?.disConnect}
              alt="disConnect"
              className="w-[1vw] h-[1vw]"
            />
            <span className="text-[0.975rem] text-[#FF543E] font-[500] pl-2">
              Disconnect
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChannelHeader;
