import React, { useEffect, useState } from "react";

// helper
import { getVariablesFromStr } from "helper/templates";
import { dateConverter } from "../../../../helper/dateConverter";

// utils
import { buttonLabels } from "../../../channels/utils/carousel";

// components
import ConversationCard from "./ConversationCard";
import { replaceFormating } from "../../helper";
import ImageMessagePreview from "./ImageMessagePreview";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import { addLineBreake } from "../../helper";
import CarouselCardPreview from "../../../../components/commonComponents/templates/CarouselCardPreview";
import { ICONS } from "../../../../assets/icons";
import Delivered from "../../../../assets/customSVG/inbox/Delivered";
import Sent from "../../../../assets/customSVG/inbox/Sent";

export default function RightSideConversationCard({
  data,
  handleReply,
  handleCopy,
  isChatResolved,
}) {
  const [carousel, setCarousel] = useState(null);
  const d = dateConverter(data?.created_at, false);

  const parseObj = (obj) => {
    if (typeof obj === "string") return JSON.parse(obj);

    return "";
  };

  const carouselData = data?.message?.template?.filter(
    (item) => item?.type === "CAROUSEL" && item?.cards
  );
  const headers = carouselData?.[0]?.cards?.map((card) =>
    card?.components?.find((component) => component?.type === "HEADER")
  );

  const bodies = carouselData?.[0]?.cards?.map((card) =>
    card?.components?.find((component) => component?.type === "BODY")
  );

  const buttonsList = carouselData?.[0]?.cards?.map(
    (card) =>
      card?.components?.find((component) => component?.type === "BUTTONS")
        ?.buttons
  );

  const btnStyle1 = `flex items-center gap-[0.5vw] h-[2vw] text-[#007AFF] text-[0.875rem] font-[500]`;

  return (
    <div className="">
      {data?.message?.type !== "window" && (
        <div className="single-conversation-container flex items-center">
          {!isChatResolved && (
            <div className="action-icons-right flex items-center pr-2">
              <img
                src={ICONS?.copyIcon}
                onClick={() => {
                  handleCopy(data?.id);
                }}
                className="pointer  w-[20px] h-[20px]"
              />
              <img
                src={ICONS?.replyIcon}
                onClick={() => {
                  handleReply(data?.id);
                }}
                className="pointer w-[18px] h-[18px]"
              />
            </div>
          )}

          <div className="relative max-w-[20.5vw] min-h-[3.5vh] bg-[#D8FBD2] rounded-[0.6vw] p-[0.8vw]">
            {/* <div className="w-0 h-0 bg-transparent border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[#D8FBD2] absolute -right-[0.5vw] top-0 rotate-180" /> */}
            {["text", "image", "video", "document", "BUTTONS"]?.includes(
              data?.message?.type
            ) ? (
              data?.message?.type === "image" ? (
                <ImageMessagePreview
                  url={data?.message?.image?.link}
                  caption={data?.message?.image?.caption}
                />
              ) : data?.message?.type === "video" ? (
                <VideoMessagePreview
                  url={data?.message?.video?.link}
                  caption={data?.message?.video?.caption}
                />
              ) : data?.message?.type === "document" ? (
                <DocumentMessagePreview
                  url={data?.message?.document?.link}
                  caption={data?.message?.document?.caption}
                />
              ) : data?.message?.type !== "button" ? (
                <p
                  className="new-body-text break-words"
                  dangerouslySetInnerHTML={{
                    __html: addLineBreake(
                      replaceFormating(data?.message?.text?.body)
                    ),
                  }}
                ></p>
              ) : (
                <div>{data?.message?.[1]?.buttons?.text?.[0]?.text}</div>
              )
            ) : (
              <>
                {data?.is_customer_msg === false &&
                Array.isArray(data?.message?.template || data?.message) ? (
                  <>
                    <ConversationCard data={data} />
                  </>
                ) : parseObj(data?.message)?.text?.body ||
                  parseObj(data?.message)?.body ? (
                  <div
                    className={`w-full break-words new-body-text rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                    dangerouslySetInnerHTML={{
                      __html: replaceFormating(
                        parseObj(data?.message)?.text?.body ||
                          parseObj(data?.message)?.body
                      ),
                    }}
                  >
                    {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                    {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                  </div>
                ) : (
                  <div
                    className={`w-full break-words new-body-text rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                    dangerouslySetInnerHTML={{
                      __html: replaceFormating(
                        data?.message?.text?.body || data?.message?.body
                      ),
                    }}
                  >
                    {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                    {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                  </div>
                )}
              </>
            )}
            <div className="flex justify-end text-[0.55vw] gap-1.5 weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-40">
              <p>{d?.timeWithMeridiem}</p>
              {(data?.status === "READ" || data?.status === "DELIVERED") && (
                <Delivered
                  color={
                    data?.status !== "READ" ? "var(--font-600)" : "#1778F2"
                  }
                />
              )}
              {data?.status === "SENT" && <Sent color={"var(--font-600)"} />}
            </div>
          </div>
        </div>
      )}
      <div className="w-100 flex">
        {headers && (
          <>
            <div className="flex align-center opacity-0">
              <img
                src={ICONS?.copyIcon}
                onClick={() => {
                  handleCopy(data?.id);
                }}
                className="pointer pr-1.5 w-[23px] h-[23px]"
              />
              <img
                src={ICONS?.replyIcon}
                onClick={() => {
                  handleReply(data?.id);
                }}
                className="pointer pr-2 w-[23px] h-[23px]"
              />
            </div>
            <div className="min-h-[4vw] max-w-[20vw]  flex gap-2.5 overflow-x-scroll listScroll1 pb-2">
              <div className=" min-h-[13rem] listScroll1 flex gap-4">
                {carouselData?.[0]?.cards?.map((card, cardIndex) => (
                  <div
                    key={cardIndex}
                    className="w-[18vw] h-auto flex flex-col justify-between mt-10 bg-[#D8FBD2] rounded-[0.6vw] rounded-tr-none p-[0.8vw]"
                  >
                    {/* Header Section */}
                    {headers[cardIndex]?.format === "IMAGE" && (
                      <img
                        src={headers[cardIndex]?.example?.header_handle}
                        alt={`Header Image ${cardIndex}`}
                        className="w-full h-[160px] object-cover rounded-[0.4vw]"
                      />
                    )}
                    {headers[cardIndex]?.format === "VIDEO" && (
                      <video
                        className="object-cover h-[150px] w-full rounded-[0.4vw]"
                        controls
                        autoPlay
                      >
                        <source
                          src={headers[cardIndex]?.example?.header_handle}
                          type="video/mp4"
                        />
                      </video>
                    )}

                    {/* Body Section */}
                    <div className="new-body-text mt-2">
                      {bodies[cardIndex]?.text && (
                        <div>{bodies[cardIndex].text}</div>
                      )}
                    </div>

                    {/* Buttons Section */}
                    <div className="button-group mt-4 flex flex-col gap-2">
                      {buttonsList[cardIndex]?.map((button, btnIndex) => (
                        <div
                          key={btnIndex}
                          className="text-[0.875rem] font-[500] flex items-center justify-center border-b border-[var(--border-50)] last:border-none"
                        >
                          {button?.type === "URL" && (
                            <button className={`${btnStyle1}`}>
                              <img
                                src={ICONS?.nounLink}
                                alt="urlIcon"
                                className="size-[0.9vw]"
                              />
                              {button?.text}
                            </button>
                          )}
                          {button?.type === "PHONE_NUMBER" && (
                            <button className={`${btnStyle1}`}>
                              <img
                                src={ICONS?.call}
                                alt="phoneNumberIcon"
                                className="size-[0.95vw]"
                              />
                              {button?.text}
                            </button>
                          )}
                          {button?.type === "COPY_CODE" && (
                            <button className={`${btnStyle1}`}>
                              <img
                                src={ICONS?.CopyLeft}
                                alt="copyCodeIcon"
                                className="size-[0.95vw]"
                              />
                              <span>Copy Offer Code</span>
                            </button>
                          )}
                          {button?.type === "QUICK_REPLY" && (
                            <div className={`${btnStyle1}`}>
                              <img
                                src={ICONS?.shareLink}
                                alt="shareBtn"
                                className="size-[0.8vw]"
                              />
                              <p>{button?.text}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {/* <div className="p-absolute">
            <img src={ICONS?.rightImageScroll} alt="rightImageScroll" />{" "}
          </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
