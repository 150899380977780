import React from "react";
import { Line } from "react-chartjs-2";
import { ICONS } from "../../../../../../assets/icons";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

// Register necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const AllConversations = ({ paidConversationsData, startDate, endDate }) => {
  const startDates = moment(startDate);
  const endDates = moment(endDate);

  // Generate labels from startDate to endDate
  const labels = [];
  let currentDate = startDates.clone();

  while (currentDate.isBefore(endDates) || currentDate.isSame(endDates)) {
    labels.push(currentDate.format("DD MMM"));
    currentDate.add(1, "days");
  }

  const getCountForDate = (date, data) => {
    const entry = data?.find((d) => moment(d?.date).format("DD MMM") === date);
    return entry ? entry.count : 0;
  };

  const counts = paidConversationsData?.reduce((acc, metric) => {
    acc[metric.key] = labels.map((date) =>
      getCountForDate(date, performanceList?.[metric.key])
    );
    return acc;
  }, {});

  const data = {
    labels: labels,
    datasets: paidConversationsData?.map((metric) => ({
      label: metric?.label,
      data: counts[metric.key],
      fill: false,
      backgroundColor: metric?.color,
      borderColor: metric?.color,
      borderWidth: 2,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        pointRadius: 10,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 14,
            weight: 500,
          },
          boxWidth: 6,
          boxHeight: 6,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        padding: 10,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw; // Get the value of the current item
            if (value === 0) {
              return null; // Hide this item from the tooltip
            }
            // Customize the label as needed
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },
        ticks: {
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },
          padding: 1,
        },
      },
      y: {
        beginAtZero: true,
        // max: yAxisMax,
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },

        ticks: {
          stepSize: 2,
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },

          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          maxTicksLimit: 10,
        },
      },
    },
  };

  return (
    <div className="px-20 pt-9 pb-4" style={{ height: "35vh" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default AllConversations;
