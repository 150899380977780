import React, { useEffect, useReducer, useState } from "react";
import { contactData, singleContactNav } from "../constants/index";
import back from "../../../assets/images/Back.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  crmSingleContactAdd,
  crmSingleContactEdit,
  crmSingleContactView,
} from "../api/Api";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  INITIAL_STATE,
  singleContactReducer,
} from "../reducers/singleContactReducer";
import { ICONS } from "../../../assets/icons";
import { Tooltip } from "antd";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import ProfileInformation from "../components/singleContact/ProfileInformation";
import AddressInfo from "../components/singleContact/AddressInfo";

const SingleContact = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [data, viewDispatch] = useReducer(singleContactReducer, INITIAL_STATE);
  const currentContact = contactData.find((item) => item.id === Number(id));
  const viewID = id;
  const dispatch = useAspDispatch();

  const crmListView = (brandId, id) => {
    if (brandId && id) {
      crmSingleContactView(brandId, id).then((res) => {
        if (res && res?.data) {
          const contactData = res?.data?.overview?.[0];
          viewDispatch({ type: "update_data", data: contactData });
        }
      });
    }
  };
  const selectedProfession = Array.isArray(data?.profession)
    ? data.profession.find((item) => item?.priority === 1)
        ?.contact_person_profession_id
    : data?.profession?.contact_person_profession_id;

  const onInfoChange = async ({ type, id, key, value, index }) => {
    const isValidZipCode = (value) => value?.length <= 6;
    if (key === "primary_zip_code" || key === "secondary_zip_code") {
      if (!isValidZipCode(value)) {
        return;
      }
    }
    viewDispatch({
      type: type,
      id: id,
      key: key,
      value: value,
      index: index,
    });

    let payload = {};

    if (key === "name") {
      payload = {
        id: value?.id,
        contact_person_profession_id: selectedProfession,
        priority: 1,
      };
    } else if (type === "update_social_profile") {
      payload = {
        person_contacts: {
          contact_type: value?.contact_type,
          contact_id: value?.contact_id,
          url: value?.url,
        },
      };
    } else if (key === "email" || key === "sms" || key === "whatsapp") {
      payload = value;
    } else if (type === "update_phone_number" && key === "update") {
      if (value.contact_id === "") {
        payload = {
          ...value,
        };
        crmAdd(viewID, payload);
        return;
      }
      payload["person_contacts"] = {
        ...value,
      };
    } else if (
      type === "update_phone_number" &&
      key === "update_priority_phonenumber"
    ) {
      payload["person_contacts"] = {
        ...value,
      };
    } else if (type === "update_email" && key === "updated_email") {
      if (value.contact_id === "" || value.contact_id === null) {
        payload = {
          ...value,
        };
        crmAdd(viewID, payload);
        return;
      }

      payload["person_contacts"] = {
        ...value,
      };
    } else if (type === "update_email" && key === "update_priority_email") {
      payload["person_contacts"] = {
        ...value,
      };
    }
    // else if (type === "profession" && key === "update_profession") {
    //   const { name, ...rest } = value;
    //   payload["profession"] = {
    //     ...rest,
    //   };
    // }
    else if (type === "profession" && key === "update_profession") {
      const { name, ...rest } = value;
      payload["profession"] = {
        ...rest,
      };
      try {
        await crmEdit(viewID, payload);
        await crmListView(currentBrand?.brand_id, viewID);
      } catch (error) {
        console.error("Error in API calls:", error);
      }
      return;
    } else if (type === "profession" && key === "update_professions_primary") {
      payload["profession"] = {
        ...value,
      };
    } else {
      payload[key] = value;
    }

    if (
      key !== "primary_address_line_1" &&
      key !== "primary_address_line_2" &&
      key !== "primary_zip_code" &&
      key !== "secondary_address_line_1" &&
      key !== "secondary_address_line_2" &&
      key !== "secondary_zip_code" &&
      key !== "add" &&
      key !== "add_email" &&
      key !== "update_profession"
    ) {
      crmEdit(viewID, payload);
    }
  };
  const crmEdit = (id, updatedFields) => {
    const brandId = currentBrand?.brand_id;

    const payLoad = {
      brand_id: brandId,
      ...updatedFields,
    };
    if (brandId && id) {
      crmSingleContactEdit(payLoad, id).then((res) => {
        if (res && res?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: res?.data?.message,
                status: "Success",
                duration: 3000,
              },
            ])
          );
          crmListView(currentBrand?.brand_id, id);
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: res?.response?.data?.error,
                status: "Error",
                duration: 3000,
              },
            ])
          );
        }
      });
    }
  };

  const crmAdd = (id, updatedFields) => {
    const brandId = currentBrand?.brand_id;

    const payLoad = {
      brand_id: brandId,
      ...updatedFields,
    };
    if (brandId && id) {
      crmSingleContactAdd(payLoad, id).then((res) => {
        if (res && res?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: res?.data?.msg,
                status: "Success",
                duration: 3000,
              },
            ])
          );
          crmListView(currentBrand?.brand_id, id);
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: res?.response?.data?.error,
                status: "Error",
                duration: 3000,
              },
            ])
          );
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      crmListView(currentBrand?.brand_id, id);
    }
  }, [currentBrand?.brand_id, id]);

  const name = `${data?.information?.first_name} ${data?.information?.last_name}`;
  const phoneNumber = data?.person_contacts?.filter(
    (contact) =>
      contact?.priority === 1 && contact?.contact_type === "MOBILE_PHONE"
  );
  const email = data?.person_contacts?.filter(
    (contact) => contact?.priority === 1 && contact?.contact_type === "EMAIL"
  );

  const location = useLocation();
  const colors = location?.state?.colors || {
    color: "defaultColor",
    backgroundColor: "defaultBackgroundColor",
  };

  return (
    //
    <div className="grid grid-rows-[10vh,calc(107vh_-_16.5vh)]">
      <div className="bg-white border-b border-b-slate-100 flex items-center">
        <div className="h-full flex items-center border-r border-r-gray-100 px-[.85vw]">
          <span
            className="cursor-pointer rounded-md hover:bg-gray-100 duration-200"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img className="w-[2vw]" src={back} alt="" />
          </span>
        </div>
        <div className="px-[.85vw]">
          <div className="flex items-center gap-[1vw]">
            <p
              className="size-[2vw] text-white grid place-content-center border border-gray-100 rounded-full overflow-hidden"
              style={{
                color: colors.color,
                backgroundColor: colors.backgroundColor,
              }}
            >
              {currentContact?.name?.url ? (
                <img
                  src={currentContact?.name?.url}
                  className="size-full object-cover"
                  alt=""
                />
              ) : (
                <span className="text-[1vw] font-[600]">
                  {data?.information?.first_name
                    ? data?.information?.first_name?.toUpperCase()?.at(0)
                    : "--"}
                </span>
              )}
            </p>
            <div className="flex flex-col ">
              <h2 className="text-black font-[600] text-[1.3vw] capitalize">
                {name}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-[3fr,10fr] bg-gray-100">
        <div className="p-[1vw] ">
          <div className="size-full rounded-lg text-[.85vw] text-[#616874] bg-white custom-shadow-1 p-[1vw] px-[1.5vw] ">
            <div className="flex flex-col items-center mb-[1vw] crm-border">
              <p
                className="size-[5vw] text-white grid place-content-center border border-gray-100 rounded-full overflow-hidden"
                style={{
                  color: colors.color,
                  backgroundColor: colors.backgroundColor,
                }}
              >
                {currentContact?.name?.url ? (
                  <img
                    src={currentContact?.name?.url}
                    className="size-full object-cover"
                    alt=""
                  />
                ) : (
                  <span className="text-[2.5vw] font-[700]">
                    {data?.information?.first_name
                      ? data?.information?.first_name?.toUpperCase()?.at(0)
                      : "--"}
                  </span>
                )}
              </p>

              <Tooltip title={name}>
                <h2 className="text-black font-[600] text-[1.3vw] mt-10 capitalize">
                  {name}
                </h2>
              </Tooltip>

              <div className="flex-row align-center justify-center gap-2 mt-[20px]">
                <img src={ICONS?.infoPhone} alt="" className="w-[20px]" />
                <h2 className="font-[400] text-[0.85vw] text-[var(--font-600)]  capitalize">
                  {`+${
                    phoneNumber?.[0]?.phone_country_code
                  } ${phoneNumber?.[0]?.phone_number?.slice(
                    0,
                    3
                  )} ${phoneNumber?.[0]?.phone_number?.slice(
                    3,
                    6
                  )} ${phoneNumber?.[0]?.phone_number?.slice(6)}`}
                </h2>
              </div>
              <div className="flex-row align-center gap-2 mt-[20px]  ">
                <img
                  src={ICONS?.infoMail}
                  alt=""
                  className="w-[20px] mb-[20px]"
                />{" "}
                {email?.[0]?.email?.length > 25 ? (
                  <Tooltip title={email?.[0]?.email}>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "15ch",
                      }}
                      className="mb-[20px]"
                    >
                      {email?.[0]?.email || "--"}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="ellipsis-container mb-[20px]">
                    {email?.[0]?.email || "--"}
                  </div>
                )}
              </div>
              {/* <div className="border w-full text-[.75vw] font-[500] px-[.7vw] py-[.4vw] border-slate-300 rounded-[.3vw] mt-10">
                New Messages
              </div> */}
            </div>

            <div className="">
              {/* <h3 className="font-[600] text-black mb-[1vw]">Information</h3> */}
              <div className="space-y-[1.2vw] ">
                {singleContactNav.map((item) => {
                  return (
                    <p
                      key={item.id}
                      className={`px-[1vw] cursor-pointer capitalize py-[0.5vw] rounded-md duration-200 ${
                        item.selected
                          ? "bg-gray-100 font-[600] text-black"
                          : "hover:bg-gray-50"
                      }`}
                    >
                      {item.label}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[calc(107vh_-_16.5vh)]">
          <div className="h-full overflow-scroll scroll_visible flex flex-col gap-[1vw] p-[1vw] pb-[.8vw] ps-[.5vw]">
            <div className="w-full rounded-lg bg-white custom-shadow-1">
              <ProfileInformation data={data} onInfoChange={onInfoChange} />
              <AddressInfo data={data} onInfoChange={onInfoChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleContact;
