import React from "react";

import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";

import { store } from "./reduxToolkit/store";
import "./styles/global.css";
import "./styles/global1.css";
import "./styles/theme.css";
// import { FlowProvider } from './modules/campaigns/components/sequence1/context/FlowContext';

const persistor = persistStore(store);

// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <PostHogProvider
      apiKey={"phc_rr5sJeSjIJ68UukWCerfBhE4ZgXzxWh9tXFNbXED7CN"}
      options={options}
    > */}
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <FlowProvider> */}
        <App />
        {/* </FlowProvider> */}
      </PersistGate>
    </Provider>
    {/* </PostHogProvider> */}
  </React.StrictMode>
);
