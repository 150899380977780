import React, { useEffect, useState } from "react";

// assets
import { images } from "assets/images/index";
import { ICONS } from "assets/icons";
import WhatsAppWithBg from "../../../assets/customSVG/WhatsAppWithBg";

// utils
import { useNavigate } from "react-router-dom";
import { Modal, Popover, Skeleton } from "antd";
import moment from "moment";

// API
import { createWABA, retryCreateWABA } from "api/apiClients/meta/whatsapp";
import { currentUserDetails } from "../../../api/Api";
import { campaignList } from "../api/Api";
import { broadcastList } from "../../../api/apiClients/broadcast";

// hooks
import useToggle from "hooks/useToggle";

// helpers
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";
import { useWeekCalender } from "hooks/calenders/useWeekCalender";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  updateCurrentBrand,
  updateCurrentUser,
} from "../../../reduxToolkit/appSlice";
import { setPageNavigate } from "../../../reduxToolkit/CampaignSlice";

// components
import NoData from "components/commonComponents/Nodata/index";
import AboutCardsContainer from "../components/AboutCard";
import BroadcastListCard from "../components/BroadcastListCard";
import Calender from "../components/Calender";
// import ConversationOverview from "../components/ConversationOverview";
import CreateWABALoader from "../../../components/commonComponents/createWABALoader";
import CreateWABAError from "components/commonComponents/createWABALoader/CreateWABAError";
import CampaignModal from "../../../components/commonComponents/campaign-popup/CampaignModal";
import WhatAppBusiness from "../components/WhatAppBusiness";
import Chart from "../components/Chart";
import ConversationSummary from "../components/ConversationSummary";
import ConversationSource from "../components/ConversationSource";
import Notifications from "../components/Notifications";

const Home = () => {
  // router
  const navigate = useNavigate();

  // redux
  const { currentBrand, currentUser } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  // calender
  const {
    selectedDate,
    weeks,
    next,
    prev,
    onChange: onSelect,
    resetToToday,
  } = useWeekCalender();

  // campaigns
  const [isCampaign, setIsCampaign] = useToggle(true);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useToggle(false);
  const [campaigns, setCampaigns] = useState([]);

  // broadcast
  const [isBroadcast, setIsBroadcast] = useToggle(false);
  const [broadcast, setBroadcast] = useState([]);

  // create WABA
  const [sessionData, setSessionData] = useState(null);
  const [data, setData] = useState(null);
  const [isRes, setIsRes] = useToggle(false);
  const [isResError, setIsResError] = useState({
    error: false,
    data: "",
  });
  const [res, setRes] = useState(null);

  useEffect(() => {
    if (currentBrand) getcampaignList(currentBrand?.brand_id);
  }, [currentBrand]);

  useEffect(() => {
    if (selectedDate)
      getBroadCastList(
        currentBrand?.brand_id,
        moment(selectedDate).format("DD-MM-YYYY"),
        moment(selectedDate).format("DD-MM-YYYY")
      );
  }, [currentBrand, selectedDate]);

  useEffect(() => {
    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    if (sessionData && data) {
      const payload = {
        brand_id: currentBrand?.brand_id,
        waba_id: sessionData?.waba_id,
        phone_number_id: sessionData?.phone_number_id,
        code: data?.authResponse?.code,
      };
      createWhatsappAccount(payload);
    }
  }, [data, sessionData]);

  const sessionInfoListener = (event) => {
    if (
      event.origin !== "https://www.facebook.com" &&
      event.origin !== "https://web.facebook.com"
    ) {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          setSessionData(data?.data);
        } else {
          const { current_step } = data.data;
        }
      }
    } catch {
      console.warn("Non JSON Response", event.data);
    }
  };

  async function launchWhatsAppSignup() {
    let data = null;
    await FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          setData(response);
        } else {
          console.warn("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "820380526237844",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          sessionInfoVersion: 2,
          setup: {},
        },
      }
    );

    return data;
  }

  // connecting the webhook
  const createWhatsappAccount = async (payload) => {
    setIsRes(true);
    try {
      const res = await createWABA(payload);
      setRes(res?.data);
      if (res?.status === 200) {
        await fetchCurrentUserDetails();
        setIsRes(false);
        // setIsResError(false);
      } else {
        const data = {
          error: true,
          data: res?.response?.data?.error,
        };
        setIsResError(data);
      }
    } catch (e) {
      console.warn(e);
      setRes(null);
    }
  };

  // if failed to connect the webhook
  const retryWABACreate = async () => {
    setIsRes(true);
    setIsResError({
      error: false,
      data: "",
    });
    try {
      const res = await retryCreateWABA({ brand_id: currentBrand?.brand_id });
      if (res.status === 200) {
        await fetchCurrentUserDetails();
        setIsRes(false);
      } else {
        setIsResError({
          error: true,
          data: res?.response?.data?.error,
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const loadDatas = async () => {
    await fetchCurrentUserDetails();
    await getcampaignList(currentBrand?.brand_id);
    await getBroadCastList(
      currentBrand?.brand_id,
      moment(selectedDate).format("DD-MM-YYYY"),
      moment(selectedDate).format("DD-MM-YYYY")
    );
  };

  const getcampaignList = async (brandId) => {
    if (brandId) {
      setIsCampaign(true);
      try {
        const res = await campaignList(brandId, {
          limit: 5,
          page: 1,
          status: 11,
        });
        setCampaigns(res?.data);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsCampaign(false);
      }
    }
  };

  const getBroadCastList = async (brandId, startDate, enddate) => {
    if (brandId && startDate && enddate) {
      setIsBroadcast(true);
      setBroadcast([]);
      try {
        const res = await broadcastList(brandId, startDate, enddate);
        if (res?.data?.broadcasts?.length > 0) {
          const filter = res?.data?.broadcasts?.filter(
            (c) => ![0, 12].includes(c.status)
          );
          setBroadcast(filter);
        } else {
          setBroadcast([]);
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setIsBroadcast(false);
      }
    }
  };

  const fetchCurrentUserDetails = async () => {
    try {
      const res = await currentUserDetails();
      dispatch(updateCurrentUser(res?.data));
      const checkBrandExist = res?.data?.active_accounts?.[0]?.brands?.filter(
        (b) =>
          b?.brand_id === currentBrand?.brand_id &&
          b?.is_whatsapp_connected !== currentBrand?.is_whatsapp_connected
      );

      setIsRes(false);
      if (checkBrandExist?.length > 0) {
        dispatch(updateCurrentBrand(checkBrandExist?.[0]));
        dispatch(updateCurrentUser(res?.data));
      } else {
        dispatch(
          updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
        );
      }
    } catch (error) {
      setIsRes(false);
      console.warn("Error fetching current user details:", error);
    }
  };

  const handleCancel = () => {
    setIsCampaignModalOpen(false);
  };

  // constants
  const aboutCardsList = [
    {
      id: 1,
      title: "Read our blog",
      image: ICONS?.homeBlogGainwix,
      content: `Learn more and and get inspired by our customers success stories.`,
      button: "Go to Gainwix's Blog",
      bgImage: "",
    },
    {
      id: 2,
      title: "Help Center",
      image: ICONS?.homeHelp,
      content: "",
      button: "Explore",
    },
    {
      id: 3,
      title: "Gainwix Video Tour",
      image: ICONS?.homeVideoTour,
      content: "",
      button: "Watch video",
    },
  ];

  return (
    <div className="w-full h-full">
      <div
        className={`w-full h-full bg-[#f9f9fc] ${
          isRes ? "opacity-[16%] blur-sm" : "opacity-100"
        }`}
      >
        <Notifications />
        <div
          className={`w-[92%] m-auto h-auto flex !py-[2vw] gap-x-[2.5vw] gap-y-[1vw] overflow-hidden overflow-y-scroll`}
        >
          <div className="w-full h-full flex flex-col justify-between gap-x-[1.5vw] gap-y-[1.5vw]">
            <div className="w-full px-[2vw] pb-[1.5vw] justify-between object-cover border-b-[1px]">
              <h1 className="text-[var(--textBlack)] mb-[0.3vw] text-[1.50rem] font-[600] flex-row items-center">
                Hello,
                {(currentUser?.first_name || currentUser?.last_name) && (
                  <div className="bg-[#DCF5E9] py-1 px-2 rounded-lg ml-3 flex items-center">
                    <>
                      {currentUser?.first_name
                        ? capitalizeFirstLetter(currentUser?.first_name)
                        : ""}
                      {currentUser?.last_name
                        ? currentUser?.first_name
                          ? ` ${capitalizeFirstLetter(currentUser?.last_name)}`
                          : capitalizeFirstLetter(currentUser?.last_name)
                        : ""}
                    </>
                    <img
                      src={ICONS?.heartEyeEmoji}
                      alt="heartEyeEmoji"
                      className="pl-3"
                    />
                  </div>
                )}
              </h1>
              <p className="text-[#616874] text-[1rem]">
                Welcome to GainWix.ai! Let's get started, Gochew Grill.
              </p>
            </div>

            <div className="flex flex-row justify-between gap-x-[1.5vw] gap-y-[1.5vw]  overflow-hidden">
              <div className="w-[73%] h-[74.5vh] max-h-[100%] flex flex-col gap-y-4 pr-[0.5vw] overflow-hidden overflow-y-scroll">
                <WhatAppBusiness />
                <Chart />
                <ConversationSummary />
                <ConversationSource />

                <div className="flex-row items-center text-[1.09rem] font-[500] py-4">
                  Get to know more about GainWix.ai
                </div>
                <div className="w-100 flex gap-[1vw]">
                  {aboutCardsList?.map((data) => (
                    <div
                      key={data?.id}
                      className="bg-white w-[22vw] p-2 rounded-xl"
                      // style={{
                      //   backgroundImage: `url(${ICONS?.homeWhatsapp})`,
                      // }}
                    >
                      <div
                        className="bg-cover w-full h-[16vh] rounded-xl p-3 flex flex-col items-start  justify-between"
                        style={{
                          backgroundImage: `url(${data?.image})`,
                        }}
                      >
                        <div className="flex">
                          {data?.id === 1 && (
                            <div>
                              <img
                                src={ICONS?.homeBlog}
                                className="pr-3 pt-1"
                              />
                            </div>
                          )}
                          <div className="pt-1.5">
                            <p className="font-[600] text-[1rem]">
                              {data?.title}
                            </p>
                            <p className="text-[0.7rem] text-[#616874]">
                              {data?.content}
                            </p>
                          </div>
                        </div>

                        <button className="flex items-center justify-end bg-[#E8E1FF] rounded-lg py-1.5 px-2.5 text-[0.8rem] text-violet-700 gap-1">
                          {data?.id === 3 && (
                            // <div
                            //   style={{
                            //     width: 0,
                            //     height: 0,
                            //     borderTop: "6px solid transparent",
                            //     borderBottom: "6px solid transparent",
                            //     borderLeft: "8px solid #6940F2",
                            //     borderRadius: "10px 10px 10px 10px",
                            //   }}
                            // ></div>
                            <img
                              src={ICONS?.homeWatch}
                              alt="homeWatch"
                              className="w-[0.8vw] h-[0.8vw]"
                            />
                          )}
                          {data?.button}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* <AboutCardsContainer /> */}

              <div className="w-[27%] h-full flex flex-col justify-between gap-x-[1.5vw] gap-y-[1.5vw]">
                <div className="w-full h-[74.5vh] max-h-[50%] bg-white rounded-[1vw] py-[0.5vw] flex flex-col">
                  <div className="px-[0.8vw] pt-[0.3vw]">
                    <Calender
                      selectedDate={selectedDate}
                      weeks={weeks}
                      next={next}
                      prev={prev}
                      onSelect={onSelect}
                      resetToToday={resetToToday}
                    />
                  </div>
                  <div className="mt-[0.8vw] h-full w-full flex flex-col  overflow-hidden pl-[1vw] pr-[0.5vw]">
                    <Skeleton
                      rootClassName="pr-[0.5vw]"
                      loading={isBroadcast}
                      paragraph={{ rows: 4 }}
                      active
                    >
                      {broadcast?.length > 0 ? (
                        <div className="w-full h-full flex flex-col justify-between">
                          <div className="w-full h-full gap-y-[0.5vw] pr-[0.5vw] overflow-hidden overflow-y-scroll listScroll">
                            {broadcast?.map((list) => {
                              return <BroadcastListCard broadcast={list} />;
                            })}
                          </div>
                          <div className="w-full h-[17%] flex items-center justify-center pt-[0.5vw]">
                            <button
                              className="w-auto h-[3vw] text-[0.85rem] weight-medium text-[#6940F2] flex gap-[0.5vw]"
                              onClick={() => {
                                navigate("/user/calendar");
                              }}
                            >
                              <img
                                src={ICONS?.viewCalendar}
                                alt="calender icon"
                                className="w-[1.2vw] h-[1.2vw]"
                              />
                              <p>View Calender</p>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <NoData
                          icon={images?.NoData}
                          title={"No broadcast have been found."}
                          titleClassName="font-md weighit-medium"
                        />
                      )}
                    </Skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
