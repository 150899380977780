import { ICONS } from "assets/icons";

export const LeftList = [
  // { id: 1, name: 'Today' },
  { id: 1, name: "Week" },
  { id: 2, name: "Month" },
  // { id: 3, name: 'All' },
];

export const initialCardDataArray = [
  {
    id: 1,
    title: "Setup an awareness",
    time: "10:00 PM",
    segment: "03",
    date: "21-3-2024",
  },
  {
    id: 2,
    title: "Setup an awareness",
    time: "10:00 PM",
    segment: "03",
    date: "21-3-2024",
  },
  // {
  //   id: 3,
  //   title: "Setup an awareness add",
  //   time: "8:00 AM",
  //   segment: "01",
  //   date: "22-3-2024",
  // },
  {
    id: 4,
    title: "Setup an awareness add",
    time: "11:30 AM",
    segment: "02",
    date: "1-4-2024",
  },
  {
    id: 5,
    title: "Setup an awareness add",
    time: "12:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 6,
    title: "Setup an awareness add",
    time: "6:10 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 7,
    title: "Setup an awareness add",
    time: "12:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 8,
    title: "Setup an awareness add",
    time: "3:00 AM",
    segment: "03",
    date: "20-3-2024",
  },
  {
    id: 9,
    title: "Setup an awareness add",
    time: "4:00 PM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 10,
    title: "Setup an awareness add",
    time: "5:30 PM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 11,
    title: "Setup an awareness add",
    time: "6:00 AM",
    segment: "03",
    date: "21-3-2024",
  },
  {
    id: 12,
    title: "Setup an awareness add",
    time: "6:00 AM",
    segment: "03",
    date: "21-3-2024",
  },
  {
    id: 13,
    title: "Setup an awareness add",
    time: "2:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 14,
    title: "Setup an awareness",
    time: "2:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 15,
    title: "Setup an awareness",
    time: "10:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 16,
    title: "Setup an awareness",
    time: "3:00 AM",
    segment: "03",
    date: "20-3-2024",
  },
  {
    id: 21,
    title: "Setup an awareness add",
    time: "6:30 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 17,
    title: "Setup an awareness",
    time: "7:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
  {
    id: 18,
    title: "Setup an awareness add",
    time: "3:00 AM",
    segment: "03",
    date: "20-3-2024",
  },
  {
    id: 19,
    title: "Setup an awareness add",
    time: "3:00 AM",
    segment: "03",
    date: "20-3-2024",
  },
  {
    id: 20,
    title: "Setup an awareness add",
    time: "3:00 AM",
    segment: "03",
    date: "22-3-2024",
  },
 
  // {
  //   id: 21,
  //   title: "Setup an awareness add",
  //   time: "12:00 AM",
  //   segment: "03",
  //   date: "22-3-2024",
  // },
  // {
  //   id: 22,
  //   title: "Setup an awareness add",
  //   time: "12:00 AM",
  //   segment: "03",
  //   date: "22-3-2024",
  // },
];

export const rowData = [
  {
    id: 1,
    showTime: "12 am",
    time: "12:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 2,
    showTime: "1 am",
    time: "1:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 3,
    showTime: "2 am",
    time: "2:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 4,
    showTime: "3 am",
    time: "3:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 5,
    showTime: "4 am",
    time: "4:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 6,
    showTime: "5 am",
    time: "5:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 7,
    showTime: "6 am",
    time: "6:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 8,
    showTime: "7 am",
    time: "7:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 9,
    showTime: "8 am",
    time: "8:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 10,
    showTime: "9 am",
    time: "9:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 11,
    showTime: "10 am",
    time: "10:00 AM",
    data: [
      "Row 1 Data 1",
      "Row 1 Data 2",
      "Row 1 Data 3",
      "Row 1 Data 4",
      "Row 1 Data 5",
      "Row 1 Data 6",
      "Row 1 Data 7",
    ],
  },
  {
    id: 12,
    showTime: "11 am",
    time: "11:00 AM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 13,
    showTime: "12 pm",
    time: "12:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 14,
    showTime: "1 pm",
    time: "1:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 15,
    showTime: "2 pm",
    time: "2:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 16,
    showTime: "3 pm",
    time: "3:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 17,
    showTime: "4 pm",
    time: "4:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 18,
    showTime: "5 pm",
    time: "5:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 19,
    showTime: "6 pm",
    time: "6:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 20,
    showTime: "7 pm",
    time: "7:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 21,
    showTime: "8 pm",
    time: "8:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 22,
    showTime: "9 pm",
    time: "9:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 23,
    showTime: "10 pm",
    time: "10:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 24,
    showTime: "11 pm",
    time: "11:00 PM",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
  {
    id: 25,
    time: "",
    data: [
      "Row 2 Data 1",
      "Row 2 Data 2",
      "Row 2 Data 3",
      "Row 2 Data 4",
      "Row 2 Data 5",
      "Row 2 Data 6",
      "Row 2 Data 7",
    ],
  },
];

export const cardDetails = [
  {
    id: 1,
    img: ICONS?.whatsApp,
    title: "VGN Homes",
    phone: "(91) 987 654 3210",
    imgtick: ICONS?.whatsApp,
  },
  {
    id: 2,
    img: ICONS?.calenderAudience,
    title: "Audience list-1",
  },
  {
    id: 3,
    img: ICONS?.calenderContact,
    title: "2000 Contacts",
  },
  {
    id: 4,
    img: ICONS?.calenderDinner,
    title: "exclusive_dinner_deal",
    span: "Marketing",
  },
];
