import React from "react";

const Chevron = ({ color, strokeColor }) => {
  return (
    <div>
      <svg
        width="8"
        height="15"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9L5 5L1 1L1 9Z"
          fill={color}
          stroke={strokeColor}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Chevron;
