//Side-Nav

import gainwixLogo from "./sidenav/gainwix-logo.svg";
import sidenav from "./sidenav/sidenav.svg";
import homeSelected from "./sidenav/selected-home.svg";
import campaignSelected from "./sidenav/selected-campaign.svg";
import calendarSelected from "./sidenav/selcected-calendar.svg";
import inboxSelected from "./sidenav/selected-inbox.svg";
import crmSelected from "./sidenav/selected-crm.svg";
import channelSelected from "./sidenav/selected-channel.svg";
import settingSelected from "./sidenav/selected-setting.svg";
import profile from "./sidenav/profile.svg";
import notifications from "./sidenav/notifications.svg";
import helpSupport from "./sidenav/helpSupport.svg";
import logout from "./sidenav/logout.svg";
import myprofile from "./sidenav/my-profile.svg";
import profileBoy from "./sidenav/profile-boy.svg";

//header-dropdown
import freePlan from "./sidenav/free-plan.svg";
import starterPlan from "./sidenav/starter-plan.svg";
import magnumPlan from "./sidenav/magnum-plan.svg";
import growthPlan from "./sidenav/growth-plan.svg";

export const ICONSS = {
  gainwixLogo: gainwixLogo,
  sidenav: sidenav,
  homeSelected: homeSelected,
  campaignSelected: campaignSelected,
  calendarSelected: calendarSelected,
  inboxSelected: inboxSelected,
  crmSelected: crmSelected,
  channelSelected: channelSelected,
  settingSelected: settingSelected,
  profile: profile,
  logout: logout,
  helpSupport: helpSupport,
  notifications: notifications,
  myprofile: myprofile,
  profileBoy: profileBoy,

  //header-dropdown

  freePlan: freePlan,
  starterPlan: starterPlan,
  magnumPlan: magnumPlan,
  growthPlan: growthPlan,
};
