import React from "react";

const CRM = ({ color }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.582 1.66699H6.66536C4.82442 1.66699 3.33203 3.15938 3.33203 5.00033V15.0003C3.33203 16.8413 4.82442 18.3337 6.66536 18.3337H14.582C16.423 18.3337 17.9154 16.8413 17.9154 15.0003V5.00033C17.9154 3.15938 16.423 1.66699 14.582 1.66699Z"
          stroke={color}
          stroke-width="1.67"
        />
        <path
          d="M8.825 11.4503C8.30104 11.8013 6.92717 12.5181 7.76395 13.4149C8.17271 13.853 8.628 14.1663 9.20033 14.1663H12.4663C13.0387 14.1663 13.494 13.853 13.9027 13.4149C14.7395 12.5181 13.3657 11.8013 12.8417 11.4503C11.6129 10.6272 10.0537 10.6272 8.825 11.4503Z"
          stroke={color}
          stroke-width="1.67"
        />
        <path
          d="M12.4974 7.49967C12.4974 8.42017 11.7512 9.16634 10.8307 9.16634C9.91023 9.16634 9.16406 8.42017 9.16406 7.49967C9.16406 6.5792 9.91023 5.83301 10.8307 5.83301C11.7512 5.83301 12.4974 6.5792 12.4974 7.49967Z"
          stroke={color}
          stroke-width="1.67"
        />
        <path
          d="M4.16536 5H2.08203M4.16536 10H2.08203M4.16536 15H2.08203"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default CRM;
