import React from "react";

const UnifiedInbox = ({ color }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.08203 10.0007C2.08203 6.2687 2.08203 4.40273 3.2414 3.24335C4.40077 2.08398 6.26675 2.08398 9.9987 2.08398C13.7306 2.08398 15.5966 2.08398 16.756 3.24335C17.9154 4.40273 17.9154 6.2687 17.9154 10.0007C17.9154 13.7326 17.9154 15.5986 16.756 16.758C15.5966 17.9173 13.7306 17.9173 9.9987 17.9173C6.26675 17.9173 4.40077 17.9173 3.2414 16.758C2.08203 15.5986 2.08203 13.7326 2.08203 10.0007Z"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9154 11.25H13.8106C13.1089 11.25 12.5575 11.8363 12.2483 12.456C11.9123 13.1292 11.2394 13.75 9.9987 13.75C8.75795 13.75 8.08514 13.1292 7.74915 12.456C7.43988 11.8363 6.88851 11.25 6.18675 11.25H2.08203"
          stroke={color}
          stroke-width="1.67"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default UnifiedInbox;
