import React from "react";

const TemplatePhoneNumbers = ({ color, fill }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.63212 4.76019L7.29658 4.00521C7.07718 3.51157 6.96748 3.26473 6.80342 3.07584C6.59781 2.83912 6.3298 2.66495 6.02998 2.57321C5.79073 2.5 5.52063 2.5 4.98042 2.5C4.19017 2.5 3.79504 2.5 3.46335 2.65191C3.07263 2.83085 2.71978 3.2194 2.57918 3.6255C2.45983 3.97024 2.49403 4.32453 2.56239 5.03308C3.29017 12.5752 7.42509 16.7101 14.9671 17.4378C15.6757 17.5062 16.03 17.5404 16.3747 17.4211C16.7809 17.2805 17.1694 16.9276 17.3484 16.5369C17.5002 16.2052 17.5002 15.8101 17.5002 15.0198C17.5002 14.4796 17.5002 14.2095 17.427 13.9702C17.3353 13.6704 17.1611 13.4024 16.9244 13.1968C16.7355 13.0327 16.4887 12.9231 15.995 12.7037L15.24 12.3681C14.7055 12.1305 14.4381 12.0117 14.1665 11.9859C13.9065 11.9612 13.6445 11.9977 13.4011 12.0924C13.1469 12.1914 12.9222 12.3787 12.4727 12.7532C12.0254 13.126 11.8017 13.3124 11.5284 13.4122C11.286 13.5007 10.9657 13.5336 10.7105 13.4959C10.4226 13.4535 10.2022 13.3358 9.76129 13.1001C8.38962 12.3671 7.63318 11.6107 6.90013 10.2389C6.66453 9.79808 6.54673 9.57758 6.5043 9.28975C6.46669 9.0345 6.49948 8.71417 6.58799 8.47192C6.68784 8.19857 6.87425 7.97488 7.24707 7.5275C7.62159 7.07807 7.80886 6.85335 7.90787 6.59909C8.00262 6.35578 8.03909 6.09367 8.01437 5.83373C7.98853 5.5621 7.86972 5.2948 7.63212 4.76019Z"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default TemplatePhoneNumbers;
