import React, { useState, useEffect, useReducer } from "react";
import "./appLayout.css";
import { Modal, Popover, Tooltip } from "antd";

//custom-svg
import Avatar from "../../assets/customSVG/Avatar";
import Chevron from "../../assets/customSVG/sideNav/Chevron";
import Home from "../../assets/customSVG/sideNav/Home";
import Channels from "../../assets/customSVG/navChannels";
import Campaigns from "../../assets/customSVG/sideNav/Campaigns";
import Calendar from "../../assets/customSVG/sideNav/Calendar";
import UnifiedInbox from "../../assets/customSVG/sideNav/UnifiedInbox";
import CRM from "../../assets/customSVG/sideNav/CRM";
import Settings from "../../assets/customSVG/Settings";

//icons
import { ICONS } from "../../assets/icons";
import { ICONSS } from "../../assets/icons/index1";

//helper
import { useAspDispatch, useAspSelector } from "../../test/jest-redux-hooks";
import { useNavigate, useLocation, useParams } from "react-router-dom";

//redux
import { setPageNavigate } from "../../reduxToolkit/CampaignSlice";
import { updateToggleToast } from "../../reduxToolkit/appSlice";

//api
import {
  carouselDiscard,
  templateDiscard,
} from "../../modules/channels/api/Api";
import { draftApi } from "../../modules/campaign/api/Api";

//common-components
import NewAlert from "../../components/commonComponents/modal/alert/NewAlert";
import HeaderDropDown from "../../components/commonComponents/HeaderDropDown/HeaderDropDown";

// constant
import { navigationConst } from "../../constant/app/appLayout";
import { myProfile } from "../../constant/app/account/account";
import Alert from "../../components/commonComponents/modals/Alert";
import { Logout } from "../../api/Api";
import useToggle from "../../hooks/useToggle";
import UsagePlan from "./UsagePlan";

function SideBar() {
  //redux
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand, toggleToast, currentUser } = useAspSelector(
    (state) => state?.app
  );
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);

  //react-redux
  const dispatch = useAspDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();

  const [hoveredNav, setHoveredNav] = useState(null);
  const [navList, setNavList] = useState(navigationConst);
  const [expand, setExpand] = useState(false);

  //modals && popover
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [draftAlertOpen, setDraftAlertOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPlansVisible, setIsPlansVisible] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [loader, setLoader] = useToggle(false);

  const { id } = useParams();
  const header = id;

  const handleExpand = () => {
    setExpand(!expand);
  };

  //modals-popover functions
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsPlansVisible(false);
  };

  const handleNo = () => {
    setIsModalVisible(false);
  };

  const handleYes = () => {
    setLoader(true);

    Logout().then((res) => {
      if (res?.status === 200) {
        navigate("/login");
        localStorage.clear();
      } else {
        console.warn("error", res);
      }
    });
  };

  const handleItemClick = () => {
    setIsModalVisible(true);
    setIsPopover(false);
  };

  const handleVisibleChange = () => {
    setIsPlansVisible(!isPlansVisible);
  };

  const discardCarousel = () => {
    if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType === "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType !== "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else {
      if (saveBroadcastDatas?.broadcast_id !== null) {
        draftApi(saveBroadcastDatas).then((createCampaignResponse) => {
          if (createCampaignResponse?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast saved successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            setDraftAlertOpen(false);
            navigate("/user/campaign/all");
          } else {
            setDraftAlertOpen(false);

            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content:
                    createCampaignResponse?.response?.data?.error ||
                    "Unable to save Broadcast",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        });
      }
    }
  };

  //modules navigate functions
  useEffect(() => {
    updateNavListState(pathname);
  }, [pathname]);

  const navigateModule = (item) => {
    // if (currentBrand && currentBrand?.is_whatsapp_connected) {
    if (
      location.pathname !==
        "/user/channels/whatsapp/edit-template/template-details" &&
      location.pathname !== `/user/campaign/time_specified/settings/${header}`
    ) {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      (templateDetails?.details?.status === "PAM" ||
        templateDetails?.details?.status === "APPROVED" ||
        templateDetails?.details?.status === "REJECTED" ||
        templateDetails?.details?.status === "PRM")
    ) {
      setDiscardAlertOpen(true);
    } else if (
      location.pathname ===
        `/user/campaign/time_specified/settings/${header}` &&
      saveBroadcastDatas?.broadcast_id !== null
    ) {
      setDraftAlertOpen(true);
    } else {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    }
    // }
  };
  const updateNavListState = (path) => {
    setNavList((prev) => {
      const newState = prev?.map((prevItem) => {
        if (
          path?.includes(prevItem?.routeUrl) ||
          (prevItem?.navName === "Campaigns" &&
            (path?.includes("/user/campaign") ||
              path?.includes("/user/api") ||
              path?.includes("/user/ContactApi")))
        ) {
          return { ...prevItem, selected: true };
        } else {
          return { ...prevItem, selected: false };
        }
      });
      return newState;
    });
  };

  return (
    <nav
      className={`side_bar_container flex-column space-between  py-4 ${
        expand ? "nav-open border border-[#F1F4F6]" : "nav-close "
      }`}
    >
      <div>
        <div
          className={`w-100 flex-row align-center   ${
            expand ? "space-between" : " translate-x-1"
          }`}
        >
          {expand ? (
            <img src={ICONS?.logoGain} alt="logoGain" />
          ) : (
            <img src={ICONS?.logoMinimize} alt="logoMinimize" className="" />
          )}
          <span
            onClick={handleExpand}
            className={`pointer z-50  ${
              expand ? "" : "rotate-180 translate-x-2"
            }`}
          >
            <img src={ICONS?.sideNavExpand} alt="sideNavExpand" />
          </span>
        </div>
        {pathname.includes("/user") && (
          <div className="header-line ptb-5 mt-20">
            <HeaderDropDown expand={expand} />
          </div>
        )}
        <div className={`${expand ? " " : "pl-[3px]"}`}>
          {navList?.map((item) => (
            <div
              key={item?.id}
              className={`navBox mt-20 ${
                item?.selected ? "active-listText1" : ""
              }`}
              onMouseEnter={() => setHoveredNav(item?.name)}
              onMouseLeave={() => setHoveredNav(null)}
              onClick={() => {
                navigateModule(item);
                if (item?.navName === "Campaigns") {
                  dispatch(setPageNavigate("All Campaigns"));
                }
              }}
              data-id="sidebar-click"
            >
              <Tooltip title={!expand ? `${item?.navName}` : ""}>
                {item?.navName === "Home" &&
                  (item?.selected ? (
                    <img src={ICONSS?.homeSelected} alt="" />
                  ) : (
                    <Home
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                    />
                  ))}
                {item?.navName === "Campaigns" &&
                  (item?.selected ? (
                    <img src={ICONSS?.campaignSelected} alt="" />
                  ) : (
                    <Campaigns
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                    />
                  ))}
                {item?.navName === "Calendar" &&
                  (item?.selected ? (
                    <img src={ICONSS?.calendarSelected} alt="" />
                  ) : (
                    <Calendar
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                    />
                  ))}
                {item?.navName === "CRM" &&
                  (item?.selected ? (
                    <img src={ICONSS?.crmSelected} alt="" />
                  ) : (
                    <CRM
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                    />
                  ))}
                {item?.navName === "Inbox" &&
                  (item?.selected ? (
                    <img src={ICONSS?.inboxSelected} alt="" />
                  ) : (
                    <UnifiedInbox
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                    />
                  ))}
                {item?.navName === "Channels" &&
                  (item?.selected ? (
                    <img src={ICONSS?.channelSelected} alt="" />
                  ) : (
                    <Channels
                      color={
                        item?.selected
                          ? "white"
                          : hoveredNav === item?.name
                          ? "var(--textBlack)"
                          : "#616874"
                      }
                      width={"22"}
                      height={"21"}
                    />
                  ))}
              </Tooltip>

              {/* {item?.navName === "Settings" &&
              (item?.selected ? (
                <img src={ICONSS?.settingSelected} alt="" />
              ) : (
                <Settings
                  color={item?.selected ? "white" : "#2d3036"}
                  width={"22"}
                  height={"21"}
                />
              ))} */}
              <span className={`navName pl-10 ${expand ? "block" : "hidden"}`}>
                {item?.navName}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <Popover
          content={<UsagePlan handleCancel={handleCancel} />}
          trigger="click"
          overlayClassName="custom-popover"
          open={isPlansVisible}
          onOpenChange={handleVisibleChange}
          arrow={false}
        >
          {/* <div className="bg-white border border-[#E8E8EA] b-radius p-[15px]">
            <div className="flex-row align-center space-between">
              <span>Free Trial</span>
              <span>Free Trial</span>
            </div>
            <div className="progress-container">
              <progress
                value={50}
                max="100"
                className="custom-progress"
                // className={`custom-progress
                //    ${progressBarColor(
                //   l?.progress_status
                // )}`}
              />
            </div>
            <span>Expires:</span>
            <button className="usage-plan flex-row align-center">
              <p>Upgrade</p>
            </button>
          </div> */}
          {expand && (
            <button
              className="upgrade-plan-button cursor-pointer ml-15"
              // onClick={() => {
              //   setIsUpgrade(true);
              //   handleCancel();
              // }}
            >
              Upgrade Plan
            </button>
          )}
        </Popover>

        <div className="flex-row  pl-10 pt-20">
          <Popover
            open={isPopover}
            onOpenChange={(open) => setIsPopover(open)}
            trigger={"click"}
            arrow={false}
            content={
              <div className="w-[300px] b-radius-6">
                <div className="flex-row gap-2 align-center bg-[var(--channelButton)] b-radius-6 ptb-20 prl-10">
                  <div className=" rounded-full flex items-center justify-center font-largest weight-medium">
                    {/* <Avatar color="#ffffff" /> */}
                    <img src={ICONSS?.profileBoy} alt="profileBoy" />
                  </div>
                  <div className="flex-column">
                    <span className="w-[25ch] truncatefont16px-500 text-[var(--textBlack)]">
                      {currentUser?.full_name}
                    </span>
                    <Tooltip
                      title={
                        currentUser?.email?.length > 25 && currentUser?.email
                      }
                    >
                      <span className="w-[25ch] truncate font14px-400 text-[var(--font-600)]">
                        {currentUser?.email}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {myProfile?.map((item) => (
                  <div
                    key={item?.id}
                    className="gap-3 flex-row align-center p-20 ml-5 font15px-400 text-[var(--font-600)] pointer"
                    onClick={() => {
                      if (item?.id === 4) {
                        handleItemClick(item);
                      }
                    }}
                  >
                    <img src={item?.icon} about="icon" />
                    {item?.label}
                  </div>
                ))}
              </div>
            }
            overlayClassName="my-profile-popover"
          >
            <div className="w-90 flex-row align-center space-between pointer">
              <div className="flex-row align-center">
                <div className="size-[2.18vw] rounded-full flex items-center justify-center font-largest weight-medium">
                  {/* <Avatar color="#ffffff" /> */}
                  <img src={ICONSS?.profileBoy} alt="profileBoy" />
                </div>
                {expand && (
                  <span className="navName text-[var(--textBlack)] pl-10">
                    My Profile
                  </span>
                )}
              </div>
              {expand && (
                <Chevron
                  color={"var(--font-600)"}
                  strokeColor={"var(--font-600)"}
                />
              )}
            </div>
          </Popover>
        </div>
      </div>

      <Alert
        open={isModalVisible}
        setOpen={setIsModalVisible}
        handleCancel={handleCancel}
        handleNo={handleNo}
        handleYes={handleYes}
        content={<p>Are you sure you want to Logout ?</p>}
        yesContent="Yes"
        noContent="No"
        loading={loader}
        yesButtonClassName="py-[0.25vw]"
        noButtonClassName="py-[0.25vw]"
      />
      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You have made some changes in the template. How would you like
                to proceed.
              </p>
            </div>
          }
          noName="Continue Editing"
          yesName="Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
      <Modal
        footer={null}
        open={draftAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={draftAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDraftAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel(header);
          }}
          handleYes={() => {
            setDraftAlertOpen(false);
          }}
        />
      </Modal>
    </nav>
  );
}

export default SideBar;
