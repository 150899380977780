import React, { useEffect, useState } from "react";
import "../../../styles/Broadcastextend.css";

import { BroadcastSegmentList } from "../../../../../constant/app/campaign/Broadcast";

import { useAspSelector } from "../../../../../test/jest-redux-hooks";
import {
  broadCastContactListApi,
  broadcastSegmentApi,
  getBroadcastPartApi,
  templateList,
} from "../../../api/Api";
import * as XLSX from "xlsx";

import SegmentFile from "./SegmentFile";
import SegmentDataFile from "./SegmentDataFile";
import SettingBroadView from "./SettingBroadView";
import BroadcastMessageView from "./BroadcastMessageView";
import BroadcastSegmentView from "./BroadcastSegmentView";
import SegmentOverview from "./SegmentOverview";
import ContactView from "./ContactView";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";

const SegmentHeader = ({ broadcastList }) => {
  const [clickedSegment, setClickedSegment] = useState(1);
  const [segment, setSegment] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isFilter, setIsFilter] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [fileId, setFileId] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const templateId = segment?.settings?.template_id;

  const { currentBrand } = useAspSelector((state) => state.app);
  const [response, setResponse] = useState([]);

  const handleSegmentClick = (dataId) => {
    setClickedSegment(dataId);
  };

  const handleContactClick = (item) => {
    setFileId(item?.id);
    setContactDetails(item);
  };

  const broadcastIds = broadcastList?.broadcast_id || broadcastList?.id;

  const broadcastPartId =
    fileId.length === 0 ? response?.[0]?.broadcastPartId : fileId;

  const { id } = useParams();
  const broadcastId = id;

  useEffect(() => {
    if (segment && segment.id && !response.length) {
      getBroadcastPartApi(segment.id, currentBrand?.brand_id)
        .then((res) => {
          if (res && res.data?.broadcast_parts?.length > 0) {
            const response = res.data.broadcast_parts;
            setResponse(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [segment, response]);

  const brandId = currentBrand?.brand_id;

  useEffect(() => {
    if (brandId && broadcastList?.campaign_id && broadcastIds) {
      // setIsLoad(true);
      broadcastSegmentApi(broadcastIds, brandId, broadcastList.campaign_id)
        .then((res) => {
          setIsLoad(false);
          if (res && res.data) {
            setSegment(res.data.broadcast);
            setIsLoad(false);
          } else {
            setIsLoad(false);
          }
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  }, [brandId, broadcastList?.campaign_id, broadcastIds]);

  const fetchBroadcastContactList = (
    brandId,
    broadcastId,
    broadcastPartId,
    payload
  ) => {
    setIsLoading(true);
    if (brandId && broadcastId) {
      broadCastContactListApi(
        brandId,
        broadcastId,
        broadcastPartId,
        payload
      ).then((res) => {
        if (res && res?.data) {
          setContactList(res?.data);
          setCount(res.data.broadcast_contacts_count);
          setIsLoading(false);
        }
      });
    }
  };

  const loadNextPage = (page) => {
    if (contactList?.broadcast_contacts?.length < count) {
      fetchBroadcastContactList(
        currentBrand?.brand_id,
        broadcastId,
        broadcastPartId,
        {
          page: page,
          limit: pageSize,
          status: selectedStatus,
        }
      );
    }
  };

  const handlePageChange = (page) => {
    if (contactList?.broadcast_contacts?.length < count) {
      loadNextPage(page);
      setPage(page);
      if (selectedStatus) {
        fetchBroadcastContactList(
          currentBrand?.brand_id,
          broadcastId,
          broadcastPartId,
          {
            page: page,
            limit: pageSize,
            status: selectedStatus,
          }
        );
      }
    }
  };

  const handlePageChangeRow = (pageSize) => {
    const pageSizeLimit = parseInt(pageSize);
    loadNextPage(pageSize);
    setPage(page);
    setPageSize(pageSizeLimit);
  };

  useEffect(() => {
    if (broadcastPartId) {
      fetchBroadcastContactList(brandId, broadcastId, broadcastPartId, {
        page: page,
        limit: pageSize,
        status: selectedStatus,
      });
    }
  }, [brandId, broadcastPartId]);

  const downloadBroadcastContacts = async (payload) => {
    setIsLoader(true);
    try {
      let allContacts = [];

      if (brandId && broadcastId) {
        const limit = contactList?.broadcast_contacts_count;
        const res = await broadCastContactListApi(
          brandId,
          broadcastId,
          broadcastPartId,
          { ...payload, limit, status: selectedStatus }
        );

        if (res && res?.data) {
          allContacts = res?.data?.broadcast_contacts;
        }
        function getStatusLabel(status) {
          switch (status) {
            case "BOUNCED":
              return "Bounced";
            case "SENDING":
              return "Sending";
            case "SENT":
              return "Sent";
            case "DELIVERED":
              return "Delivered";
            case "READ":
              return "Opened";
            case "REPLIED":
              return "Lead";
            default:
              return "Unknown";
          }
        }

        const formattedContacts = allContacts.map((contact) => ({
          "From Number": contact.from_number,
          "To Number": contact.to_number,
          Status: getStatusLabel(contact.status),
          "Campaign Type": contact.campaign_type,
          "Send At": new Date(contact.send_at).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            dateStyle: "medium",
            timeStyle: "medium",
          }),
        }));

        // Generate Excel file
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(formattedContacts);
        XLSX.utils.book_append_sheet(wb, ws, "Broadcast Contacts");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

        function s2ab(s) {
          setIsLoader(false);

          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }

        saveAs(
          new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
          `${segment?.name}.xlsx`
        );
      }
    } catch (error) {
      console.error("Error fetching broadcast contacts:", error);
      // Handle error, show user-friendly message or log the error for debugging
    }
  };

  const templateSearch = (value) => {
    setIsFilter(value);
    fetchBroadcastContactList(
      currentBrand?.brand_id,
      broadcastId,
      broadcastPartId,
      {
        filter: value,
        name: value,
        status: selectedStatus,
      }
    );
  };

  const onFilterChange = (status) => {
    const updatedStatus = selectedStatus.includes(status)
      ? selectedStatus.filter((s) => s !== status)
      : [...selectedStatus, status];
    setSelectedStatus(updatedStatus);
    fetchBroadcastContactList(
      currentBrand?.brand_id,
      broadcastId,
      broadcastPartId,
      {
        status: updatedStatus,
        limit: pageSize,
        page: page,
      }
    );
  };

  const handleContactClick1 = (item) => {
    setContactDetails(item);
    setPageSize(parseInt(item?.value));
    fetchBroadcastContactList(
      currentBrand?.brand_id,
      broadcastId,
      broadcastPartId,
      {
        limit: parseInt(item?.value),
        page: 1,
        status: selectedStatus,
      }
    );
  };

  const fetchSingleTemplateLists = (brandId, templateId) => {
    if (brandId) {
      templateList(brandId, templateId)
        .then((res) => {
          if (res?.status === 200) {
            setSelectedTemplate(res?.data?.wapp_template);
          }
        })
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      setSelectedTemplate([]);
    }
  };

  useEffect(() => {
    if (templateId) {
      fetchSingleTemplateLists(currentBrand?.brand_id, templateId);
    }
  }, [currentBrand, templateId]);

  return (
    <div
      className="segmentHeader-container flex-column"
      style={{ border: "1px solid var(--font-50)" }}
    >
      <div className=" mmm flex-row  w-100  align-center">
        {BroadcastSegmentList?.map((data) => (
          <div
            className={`ss flex-row align-center space-evenly pointer ${
              clickedSegment === data.id ? "green-background" : ""
            }`}
            style={{
              borderBottom:
                clickedSegment === data.id
                  ? "3px solid var(--primary)"
                  : "none",
            }}
            key={data?.id}
            onClick={() => handleSegmentClick(data.id)}
            data-testid="segment-header"
          >
            <span className="cursor-pointer">{data?.name}</span>
          </div>
        ))}
      </div>
      <div className="overview" style={{ padding: "20px 30px", width: "100%" }}>
        {clickedSegment === 1 && (
          <div>
            <SegmentOverview segment={segment} />
            <SegmentFile segment={segment} isLoad={isLoad} />
          </div>
        )}
        {clickedSegment === 2 && <SettingBroadView segment={segment} />}
        {clickedSegment === 3 && (
          <SegmentDataFile segment={segment} response={response} />
        )}
        {clickedSegment === 4 && (
          <BroadcastSegmentView segment={segment} response={response} />
        )}
        {clickedSegment === 5 && (
          <BroadcastMessageView
            segment={segment}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}

        {clickedSegment === 6 && (
          <ContactView
            contactList={contactList}
            handlePageChange={handlePageChange}
            page={page}
            setPage={setPage}
            count={count}
            loadNextPage={loadNextPage}
            templateSearch={templateSearch}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            handleContactClick={handleContactClick}
            fileId={fileId}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handlePageChangeRow={handlePageChangeRow}
            handleContactClick1={handleContactClick1}
            isLoading={isLoading}
            onFilterChange={onFilterChange}
            selectedStatus={selectedStatus}
            segment={segment}
            downloadBroadcastContacts={downloadBroadcastContacts}
            isLoader={isLoader}
          />
        )}
      </div>
    </div>
  );
};

export default SegmentHeader;
