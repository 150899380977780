import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// w-[clamp(280px,100%,40%)]

//constants
import {
  COUNTRYLIST,
  freeConversationsData,
  insightsChart,
  PHONENUMBERLIST,
  allConversationsData,
  paidConversationsData,
  approximateChargesData,
} from "../../../constant/insights";

// comnponents
import WhatsappMainHeaders from "../../../components/whatsapp/WhatsappMainHeaders";
import { useAspSelector } from "../../../../../test/jest-redux-hooks";
import { useOutletContext } from "react-router-dom";
import AllConversations from "./components/AllConversations";
import FreeConversations from "./components/FreeConversations";
import PaidConversations from "./components/PaidConversations";
import ApproximateCharges from "./components/ApproximateCharges";
import BestPerformTemplates from "./components/BestPerformTemplates";
import TemplateOverview from "./components/TemplateOverview";
import InsightsFilter from "./components/InsightsFilter";

export default function Insights() {
  const { currentBrand } = useAspSelector((state) => state?.app);
  const { syncing, isLoading, dataList, onClick } = useOutletContext();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [active, setActive] = useState("Paid Conversation");

  //phone number filter state
  const [phoneNumbersList, setphoneNumbersList] = useState(null);
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState([]);

  //country filter state
  const [countryList, setCountryList] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState([]);

  useEffect(() => {
    setphoneNumbersList(PHONENUMBERLIST);
    setCountryList(COUNTRYLIST);
  }, []);

  useEffect(() => {
    getSelectedPhoneNumberListId();
    getSelectedCountryListId();
  }, [phoneNumbersList, countryList]);

  //-------------------------country filter---------------------------
  const getSelectedCountryListId = useCallback(
    () => setSelectedCountryId(countryList?.filter((t) => t?.isSelected)),
    [countryList]
  );

  const onCountryChange = (data) => {
    const res = countryList?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setCountryList(res);
  };

  const clearCountryList = () => {
    const res = countryList?.map((t) => {
      return { ...t, isSelected: false };
    });
    setCountryList(res);
  };

  //--------------------------phonenumber filter-------------------
  const getSelectedPhoneNumberListId = useCallback(
    () =>
      setSelectedPhoneNumberId(phoneNumbersList?.filter((t) => t?.isSelected)),
    [phoneNumbersList]
  );

  const onPhoneNumberChange = (data) => {
    const res = phoneNumbersList?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setphoneNumbersList(res);
  };

  const clearPhoneNumberList = () => {
    const res = phoneNumbersList?.map((t) => {
      return { ...t, isSelected: false };
    });

    setphoneNumbersList(res);
  };

  const fetchApiByFilter = (filter) => {
    switch (filter) {
      case "Last 7 Days":
        break;
      case "This Month":
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full bg-[var(--channelButton)]">
      <div className="!bg-white p-3 rounded-2xl">
        {currentBrand?.is_whatsapp_connected === true && (
          <WhatsappMainHeaders
            onClick={onClick}
            data={dataList}
            isLoading={isLoading}
            syncing={syncing}
          />
        )}
        <InsightsFilter
          selectedPhoneNumberId={selectedPhoneNumberId}
          phoneNumbersList={phoneNumbersList}
          selectedCountryId={selectedCountryId}
          countryList={countryList}
          fetchApiByFilter={fetchApiByFilter}
          onPhoneNumberChange={onPhoneNumberChange}
          clearPhoneNumberList={clearPhoneNumberList}
          onCountryChange={onCountryChange}
          clearCountryList={clearCountryList}
        />
      </div>

      <div>
        <h2 className="pt-6 pb-4 text-[1.1rem] font-[600]">Overview</h2>
        <TemplateOverview />
      </div>

      <div>
        <h2 className="py-6 text-[1.1rem] font-[600]">
          Best Performing Templates
        </h2>
        <BestPerformTemplates />
      </div>

      <div className="bg-white rounded-[1vw] w-100 mt-6">
        <div className="flex-row justify-between items-center pt-20 prl-10 ml-10 mr-10 border-b-[1px] pb-2">
          <p className="flex-row items-center text-[1.1rem] font-[500]">
            All Conversations
          </p>
        </div>

        <AllConversations
          allConversationsData={allConversationsData}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <div className="bg-white rounded-[1vw] w-100 mt-6">
        <div className="flex-row justify-between items-center pt-20 prl-10 ml-10 mr-10 border-b-[1px] pb-2">
          <p className="flex-row items-center text-[1.1rem] font-[500]">
            Free Conversations
          </p>
        </div>

        <FreeConversations
          freeConversationsData={freeConversationsData}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <div className="bg-white rounded-[1vw] w-100 mt-6">
        <div className="flex-row gap-5 items-center pt-20  ml-10 mr-10 border-b-[1px] pb-2 relative">
          {insightsChart.map(({ id, text }) => (
            <p
              key={id}
              onClick={() => setActive(text)}
              className={`cursor-pointer text-[1.1rem] font-[500] prl-10 ${
                active === text ? "text-[var(--new-primary)]" : ""
              } relative`}
            >
              {text}
              {active === text && (
                <span className="absolute -bottom-[9px] left-0 w-full h-[1.5px] bg-[var(--new-primary)]"></span>
              )}
            </p>
          ))}
        </div>
        {active === "Paid Conversation" && (
          <PaidConversations
            paidConversationsData={paidConversationsData}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {active === "Approximate Charges" && (
          <ApproximateCharges
            approximateChargesData={approximateChargesData}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    </div>
  );
}
