import React from "react";

//assets
import Download from "../../../../../../assets/customSVG/campaigns/Download";
import { Popover, Tooltip } from "antd";

//helper
import { cn } from "../../../../../../helper/cn";

//constants
import { COUNTRYLIST, PHONENUMBERLIST } from "../../../../constant/insights";
import { HomeFilterList } from "../../../../../../constant/app/Filter/Filter";

//components
import FilterHome from "../../../../../../components/commonComponents/filterHome/FilterHome";
import CheckBoxSqure from "../../../../../../components/commonComponents/checkbox/CheckBoxSqure";

const InsightsFilter = ({
  selectedPhoneNumberId,
  phoneNumbersList,
  selectedCountryId,
  countryList,
  fetchApiByFilter,
  onPhoneNumberChange,
  clearPhoneNumberList,
  onCountryChange,
  clearCountryList,
}) => {
  return (
    <div className="flex justify-between pt-4">
      <div className="flex items-center gap-3">
        <Popover
          trigger={["click"]}
          arrow={false}
          placement="bottom"
          content={
            <div className="min-w-56 px-3.5 py-2.5">
              <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                <div className="text-[var(--font-400)]">Status</div>
                <div
                  className={cn(
                    "text-[#F96056]",
                    selectedPhoneNumberId?.length > 0
                      ? "cursor-pointer opacity-100"
                      : "cursor-not-allowed opacity-50"
                  )}
                  onClick={() => {
                    if (selectedPhoneNumberId?.length > 0)
                      clearPhoneNumberList();
                  }}
                >
                  Clear
                </div>
              </div>
              {phoneNumbersList?.map((s) => {
                return (
                  <div
                    className={cn(
                      "w-full h-12 flex items-center gap-2.5 py-1 cursor-pointer pl-10"
                    )}
                    onClick={() => {
                      onPhoneNumberChange(s);
                    }}
                    key={s?.id}
                  >
                    <CheckBoxSqure
                      value={s?.isSelected}
                      onChange={(e) => {}}
                      className="size-4"
                    />
                    <p>{s?.label}</p>
                  </div>
                );
              })}
            </div>
          }
        >
          <div className="group/goal bg-[var(--channelButton)] flex items-center gap-2.5 px-2 py-2 rounded-[0.4vw] cursor-pointer">
            <div className="campaign-filters flex items-center">
              Phone number
              <div
                className={`ml-1 ${
                  selectedPhoneNumberId?.length === PHONENUMBERLIST?.length ||
                  selectedPhoneNumberId?.length === 0
                    ? ""
                    : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
                }`}
              >
                {selectedPhoneNumberId?.length === PHONENUMBERLIST?.length ||
                selectedPhoneNumberId?.length === 0
                  ? "(All)"
                  : selectedPhoneNumberId?.length}
              </div>
            </div>
            <div
              className={cn(
                "transition-transform duration-200 ease-linear"
                // false ? "-rotate-180" : "rotate-0",
                // isLoading ? "cursor-not-allowed" : "cursor-pointer"
              )}
            >
              <div
                className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
              ></div>
            </div>
          </div>
        </Popover>

        <Popover
          trigger={["click"]}
          arrow={false}
          placement="bottom"
          content={
            <div className="min-w-56 px-3.5 py-2.5">
              <div className="w-full flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                <div className="text-[var(--font-400)]">Status</div>
                <div
                  className={cn(
                    "text-[#F96056]",
                    selectedCountryId?.length > 0
                      ? "cursor-pointer opacity-100"
                      : "cursor-not-allowed opacity-50"
                  )}
                  onClick={() => {
                    if (selectedCountryId?.length > 0) clearCountryList();
                  }}
                >
                  Clear
                </div>
              </div>

              <div className="h-[30vh] overflow-y-scroll listScroll">
                {countryList?.map((s) => {
                  return (
                    <div
                      className={cn(
                        "w-full h-12 flex items-center gap-2.5 py-1 cursor-pointer pl-10"
                      )}
                      onClick={() => {
                        onCountryChange(s);
                      }}
                      key={s?.id}
                    >
                      <CheckBoxSqure
                        value={s?.isSelected}
                        onChange={(e) => {}}
                        className="size-4"
                      />
                      <p>{s?.label}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        >
          <div className="group/goal bg-[var(--channelButton)] flex items-center gap-2.5 px-2 py-2 rounded-[0.4vw] cursor-pointer">
            <div className="campaign-filters flex items-center">
              Country
              <div
                className={`ml-1 ${
                  selectedCountryId?.length === COUNTRYLIST?.length ||
                  selectedCountryId?.length === 0
                    ? ""
                    : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
                }`}
              >
                {selectedCountryId?.length === COUNTRYLIST?.length ||
                selectedCountryId?.length === 0
                  ? "(All)"
                  : selectedCountryId?.length}
              </div>
            </div>
            <div
              className={cn(
                "transition-transform duration-200 ease-linear"
                // false ? "-rotate-180" : "rotate-0",
                // isLoading ? "cursor-not-allowed" : "cursor-pointer"
              )}
            >
              <div
                className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
              ></div>
            </div>
          </div>
        </Popover>
      </div>

      <div className="flex items-center gap-3">
        <div>
          <FilterHome
            fetchApiByFilter={fetchApiByFilter}
            filterList={HomeFilterList}
          />
        </div>
        <Tooltip title="Download Reports">
          <div className="border py-[0.497vw] px-2 rounded-md pointer">
            <Download
              className="stroke-[var(--font-600)] group-hover:stroke-[var(--new-primary)]"
              color=""
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default InsightsFilter;
