import React from "react";

//assets
import { ICONS } from "../../../../../../assets/icons";
import Calendar from "../../../../../../assets/customSVG/navCalendar";

//helper
import { cn } from "../../../../../../helper/cn";
import { dateConverter } from "../../../../../../helper/dateConverter";

const list = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

const bodyStyle = "text-sm weight-medium text-[var(--font-600)] text-left pl-2";

const BestPerformTemplates = () => {
  return (
    <div className="w-full h-full bg-white rounded-2xl px-5">
      <div className="w-full relative overflow-hidden">
        {/* <Skeleton active paragraph={{ rows: 6 }}> */}
        <div className="w-full h-14 sticky top-0 flex items-center pr-1">
          <div className={cn("new-header2", "w-[40%]")}>Name</div>
          <div className={cn("new-header2", "w-[17%]")}>Delivery rate</div>
          <div className={cn("new-header2", "w-[16%]")}>Open rate</div>
          <div className={cn("new-header2", "w-[17%]")}>Bounce rate</div>
          <div className={cn("new-header2", "w-[9%]")}>Actions</div>
        </div>

        <div
          className={`min-w-full h-full`}
          id="scrollableDiv"
          // ref={listContainerRef}
        >
          <>
            {list?.map((l) => {
              const date = dateConverter(l?.created_at);

              return (
                <div
                  key={l?.id}
                  className="w-full odd:bg-gray-50 my-1 py-4 flex items-center rounded-lg pointer"
                >
                  <div className={cn(bodyStyle, "w-[40%] cursor-pointer pl-3")}>
                    <div className="flex flex-col gap-2.5 justify-between text-left">
                      <div className="flex items-center gap-2">
                        {l?.name?.length > 15 ? (
                          <Tooltip title={l?.name} placement="top">
                            <div className="new-header truncate max-w-[15ch]">
                              {l?.name &&
                                l.name.trim().charAt(0).toUpperCase() +
                                  l.name.trim().slice(1).toLowerCase()}
                            </div>
                          </Tooltip>
                        ) : (
                          <>
                            <div className="new-header">
                              black_friday_promotion_sale
                              {/* {l?.name &&
                                  l.name.trim().charAt(0).toUpperCase() +
                                    l.name.trim().slice(1).toLowerCase()} */}
                            </div>
                            <div className="bg-[#FF3629] text-white rounded-2xl px-2.5 py-[0.1vw] text-[0.75rem]">
                              Trending
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex items-center gap-1.5">
                        <div className="size-5 rounded-[4px]">
                          <Calendar
                            color={"var(--BG-600)"}
                            width={"22"}
                            height={"21"}
                          />
                        </div>
                        <div className="text-xs">
                          {/* Created @ {date?.monthInShort} {date?.date},{" "}
                              {date?.hours}:{date?.minutes}
                              {date?.meridiem} */}
                          Created @ {date?.monthInShort} {date?.date},{" "}
                          {date?.timeWithMeridiem}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={cn("new-body-text", "w-[17%] pl-5")}>
                    {"78%"}
                  </div>
                  <div className={cn("new-body-text", "w-[16%] pl-5")}>
                    {"78%"}
                  </div>
                  <div className={cn("new-body-text", "w-[17%] pl-5")}>
                    {"78%"}
                  </div>

                  <div
                    className={cn(bodyStyle, "w-[9%] flex items-center gap-3")}
                  >
                    <img
                      src={ICONS?.campaignEye}
                      alt="analytics"
                      className="hover:bg-gray-100 p-10 b-radius-6"
                    />
                  </div>
                </div>
              );
            })}
          </>
        </div>
        {/* </Skeleton> */}
      </div>
    </div>
  );
};

export default BestPerformTemplates;
