import React, { useEffect, useState } from "react";
import "../style/auth.css";

// utils
import { useNavigate } from "react-router-dom";

// api
import { currentUserDetails } from "../../../api/Api";

// assets
import { ICONS } from "../../../assets/icons";

// redux
import { useAspDispatch } from "../../../test/jest-redux-hooks";
import {
  UpdateAccountAndBrandId,
  updateAccountDetails,
} from "../../../reduxToolkit/appSlice";

// hooks
import useToggle from "../../../hooks/useToggle";

// components
import Loader from "../../../components/commonComponents/Loader/Index";
import { Tooltip } from "antd";

const Accounts = () => {
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useToggle(false);
  const dispatch = useAspDispatch();

  const fetchCurrentUserDetails = async () => {
    setLoading(true);
    try {
      const res = await currentUserDetails();
      if (res && res?.data && res?.data?.active_accounts?.length > 0) {
        setLoading(false);
        setBrandList(res?.data?.active_accounts);
      } else {
        // navigate(`/user/login`);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching current user details:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUserDetails();
  }, []);

  const handleClick = (account) => {
    const data = {
      currentAccount: account,
      currentBrand: null,
    };
    dispatch(
      UpdateAccountAndBrandId({
        acc_id: account?.account_id,
        brand_id: account?.brands?.[0]?.brand_id,
      })
    );
    if (account?.brands?.[0]) data["currentBrand"] = account?.brands?.[0];
    dispatch(updateAccountDetails(data));
    if (account?.brands?.length > 0) navigate("/user/home");
    else navigate("/Accounts");
  };

  return (
    <>
      {!loading ? (
        <div className="account-card-main-container w-60 flex-column space-between listScroll mt-6">
          {brandList?.map((account, index) => {
            return (
              <Tooltip
                title={
                  account?.brands?.length === 0 &&
                  "You are not allowed to perform this action"
                }
                width="200vw"
              >
                <div
                  className={`account-card-g flex-row align-center space-between pointer ${
                    account?.brands?.length > 0
                      ? "pointer"
                      : "cursor-not-allowed"
                  }`}
                  onClick={() =>
                    account?.brands?.length > 0 && handleClick(account)
                  }
                  key={account?.id || index}
                >
                  <div className=" flex-row align-center w-100 h-50 ">
                    <div className="account-initial">
                      {account.account_name[0]}
                    </div>
                    <div className="account-card-titles-g flex-column ml-10">
                      <span>{account?.account_name}</span>
                      <div className="account-card-content-g flex-row align-center mt-10">
                        <img src={ICONS?.brands} alt="brands" />
                        <span>{account?.active_brand_counts} Brands</span>
                      </div>
                    </div>
                  </div>
                  <img src={ICONS?.accountsArrow} alt="icon1" />
                  <img src={ICONS?.accountsArrowHover} alt="icon2" />
                </div>
              </Tooltip>
            );
          })}
        </div>
      ) : (
        <div style={{ marginTop: "30px" }}>
          <Loader Width={20} Height={20} loaderBg="white" />
        </div>
      )}
    </>
  );
};

export default Accounts;
