import React from "react";

//constants
import { overview } from "../../../../constant/insights";

const TemplateOverview = () => {
  return (
    <div className="flex flex-wrap justify-between gap-5">
      {overview?.map((c) => {
        return (
          <div key={c.label} className="px-5 py-3 rounded-2xl flex-1 bg-white">
            <div className="w-full min-w-[200px] h-11 flex flex-1 items-center justify-between font-[500]">
              <div className="flex items-center gap-2">
                <div className="size-2.5 rounded-[3px] bg-[#5C3BBF]" />
                <div className="text-[1.07rem] font-[500]">{c.label}</div>
              </div>
              <div className="text-[0.9rem] font-[600]">{c.value}</div>
            </div>
            {c.values?.map((v) => {
              return (
                <div key={v.label} className="w-full h-11 flex items-center">
                  <div className="w-full h-auto flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div
                        className={`size-2.5 rounded-[3px]`}
                        style={{
                          background: v.color,
                        }}
                      />
                      <div className="text-[1rem] text-[#4C515B] font-[500]">
                        {v.label}
                      </div>
                    </div>
                    <div className="text-[0.9rem] font-[600]">{v.value}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TemplateOverview;
